import { apiConfig } from "./apiConfig";
import { handleResponse, handleError } from "./apiHelper";

export const reportApi = {
  getAccountingReport,
  getBookingBalanceReport,
  getOccupancyReport,
  getSalesReport,
};


function getAccountingReport(tenant_id, checkOutDate, checkInDate) {
  return gqlPostQueryResult(`query {
  get_accounting_report(args: {p_tenant_id: "${tenant_id}", p_end_date: "${checkOutDate}", p_tart_data:"${checkInDate}"}, order_by: {total_cash_amount: asc}) {
    total_cash_amount
    total_credit_card_amount
    total_refund_amount
    total_room_revenue
    total_service_charge
    total_service_revenue
  }
}`);
}

function getSalesReport(tenant_id, checkOutDate, checkInDate) {
  return gqlPostQueryResult(`query {
  get_sales_report(args: {p_tenant_id: "${tenant_id}", end_date: "${checkOutDate}", start_data: "${checkInDate}"}) {
    booking_source, 
    booking_no,     
    order_no,       
    item_name,      
    qty,            
    service_charge,  
    total_amount,
    created_at
  }
}`);
}

function getBookingBalanceReport(tenant_id) {
  return gqlPostQueryResult(`query {
      get_booking_balance_report(args: {p_tenant_id: "${tenant_id}"}) {
        booking_no,
        guest_name,
        check_in_date,
        check_out_date,
        charge_amount,
        paid_amount,
        remaining_amount,
        id
      }
    }`);
}

function getOccupancyReport(tenant_id, checkOutDate, checkInDate) {
  return gqlPostQueryResult(`query {
    get_occupancy_report(args: {p_end: "${checkOutDate}", p_start: "${checkInDate}", p_tenant_id: "${tenant_id}"}, order_by: {a_date: asc}) {
       occupied_rooms_qty,
       room_qty,
       room_type
       a_date,
      }
    }`);
}

function gqlPostQueryResult(_query) {
  const gql = { query: _query };

  const url = apiConfig.hasuraUrl;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-hasura-admin-secret": apiConfig.hasuraSecret,
      // 'Authorization': `Bearer ${apiConfig.getRideumToken()}`,
      "Content-Type": "application/json-patch+json",
    },
    body: JSON.stringify(gql),
  };

  return fetch(url, requestOptions)
    .then(handleResponse, handleError)
    .then((data) => {
      return data;
    });
}
