import { useHistory } from "react-router-dom";
import FrontDesk from "./FrontDesk";


const Dashboard = () => {
    const authInfo = JSON.parse(localStorage.getItem('auth'));
    let history = useHistory();

    const noTenantSelected = authInfo?.tenant?.id === undefined;

    return (
        <>
        {
            authInfo.role === "manager" && noTenantSelected ? history.push('/tenants') 
            :
            authInfo.role === 'housekeeper' ? 
            history.push('/housekeeping')  :
            <FrontDesk />
        }
        </>
    );
}

export default Dashboard;