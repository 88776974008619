import React, { useContext, useEffect, useRef } from "react";
import {
    FormWithRedirect,
    TextInput,
    NumberInput,
    SelectInput,
    required,
    useTranslate,
    ReferenceInput,
    SaveButton,
    FormDataConsumer,
    TextField,
    FunctionField,
    DateInput,
    minValue,
    maxValue,
    number,
} from "react-admin";
import { Typography, InputAdornment, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { DataProviderContext } from "react-admin";
import { useNotify } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ClearIcon from "@material-ui/icons/Clear";
import Chip from "@material-ui/core/Chip";
import { bookingStatus } from "../../models/bookingStatus";
import { accommodationStatus } from "../../models/accommodationStatus";
import { orderStatus } from "../../models/orderStatus";
import { depositStatus } from "../../models/depositStatus";
import { refundStatus } from "../../models/refundStatus";
import { invoiceStatus } from "../../models/invoiceStatus";
import { formatter } from "../../utils/formatter";
import moment from "moment";
import { DataGrid } from "@material-ui/data-grid";
import PageviewIcon from "@material-ui/icons/Pageview";
import VisibilityIcon from "@material-ui/icons/Visibility";
import PrintIcon from "@material-ui/icons/Print";
import SaveIcon from "@material-ui/icons/Save";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import { paymentApi } from "../../api/paymentApi";
import { authApi } from "../../api/authApi";
import { paymentReferenceType } from "../../models/paymentReferenceType";
import { bookingApi } from "../../api/bookingApi";
import icBleudine from "../../images/ico_bleudine.png";
import Dialog from "@material-ui/core/Dialog";
import DialogContentText from "@material-ui/core/DialogContentText";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReactToPrint from "react-to-print";
import { computeRoomChargesForAccommodation } from "../../services/pricingService";

const useStyles = makeStyles((theme) => ({
    formGrid: {
        paddingTop: "0!important",
        paddingBottom: "0!important",
    },
    formInput: {
        marginTop: "0!important",
        marginBottom: "0!important",
    },
    textBold: {
        fontWeight: "bold",
    },
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    subCardWrap: {
        minHeight: 400,
        position: "relative",
    },
}));

const DialogTitle = (props) => {
    const classes = useStyles();
    const { children, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton
                    aria-label="close"
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
};

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

var today = new Date();
var tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

const BookingInfo = () => {
    const classes = useStyles();
    return (
        <Card className={classes.subCardWrap}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Booking Info
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Booking No</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <FunctionField
                                render={(record) => formatter.bookingNo(record.booking_no)}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Booking Source</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <TextField source="booking_source" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <FunctionField
                            render={(record) => record.booking_source == "Channel" ? <Typography gutterBottom>Client</Typography> : <></>}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <FunctionField
                            render={(record) => record.booking_source == "Channel" ? <Typography gutterBottom align="right"><TextField source="cm_client.name" /></Typography> : <></>}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <FunctionField
                            render={(record) => record.booking_source == "Channel" ? <Typography gutterBottom>Itinerary No</Typography> : <></>}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <FunctionField
                            render={(record) => record.booking_source == "Channel" ? <Typography gutterBottom align="right"><TextField source="cm_itenerary_no" /></Typography> : <></>}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Check In Date</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <TextField source="check_in_date" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Check Out Date</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <TextField source="check_out_date" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Adult Quantity</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <TextField source="adult_qty" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Children Quantity</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <TextField source="children_qty" />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Status</Typography>
                    </Grid>
                    <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        style={{ textAlign: "right" }}
                    >
                        <FunctionField
                            render={(record) => (
                                <Chip
                                    label={record.e_booking_status.comment}
                                    style={{
                                        color: "white",
                                        background: bookingStatus.getColor(
                                            record.e_booking_status.id
                                        ),
                                    }}
                                />
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={12} className={classes.formGrid}>
            <br/>
            <Button
              variant="contained"
              color="primary"
            >
              View Booking Details
            </Button>
          </Grid> */}
                </Grid>
            </CardContent>
        </Card>
    );
};

const GuestInfo = () => {
    const classes = useStyles();

    return (
        <Card className={classes.subCardWrap}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Primary Guest Info
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography gutterBottom align="right">
                            <FunctionField
                                render={(record) => {
                                    if (record.guest.bleudine_user_id != null) {
                                        return (
                                            <img src={icBleudine} style={{ width: 25, height: 25 }} />
                                        );
                                    }
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Name</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <FunctionField
                                render={(record) => `${record.guest.display_name}`}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Phone Number</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <FunctionField
                                render={(record) =>
                                    `${record.guest.phone_no != null ? record.guest.phone_no : "-"
                                    }`
                                }
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Email</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <FunctionField
                                render={(record) =>
                                    `${record.guest.email != null ? record.guest.email : "-"
                                    }`
                                }
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Nationality</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            <FunctionField
                                render={(record) =>
                                    `${record.guest.e_nationality != null
                                        ? record.guest.e_nationality.comment
                                        : "-"
                                    }`
                                }
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        <FunctionField
                            render={(record) => (
                                <Button
                                    style={{ bottom: 20, position: "absolute" }}
                                    variant="contained"
                                    color="primary"
                                    target="_blank"
                                    href={"/guests/" + record.guest.id}
                                >
                                    View Guest Profile
                                </Button>
                            )}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const BalanceInfo = ({
    booking,
    summaryAmount,
    summaryDeposit,
    summaryPaid,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();

    return (
        <Card className={classes.subCardWrap}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Balance Info
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Amount</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            {formatter.formatMoney(
                                summaryAmount,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Deposit</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            -(
                            {formatter.formatMoney(
                                summaryDeposit,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )}
                            )
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Paid</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom align="right">
                            -(
                            {formatter.formatMoney(
                                summaryPaid,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )}
                            )
                        </Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography gutterBottom>Due Amount</Typography>
                    </Grid>
                    <Grid item xs={6} className={classes.formGrid}>
                        <Typography
                            gutterBottom
                            align="right"
                            style={{
                                fontWeight: "bold",
                                color:
                                    summaryAmount - summaryDeposit - summaryPaid > 0
                                        ? "red"
                                        : "green",
                            }}
                        >
                            {formatter.formatMoney(
                                summaryAmount - summaryDeposit - summaryPaid,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const AccommodationInfo = ({
    setOpenAccommodation,
    setSelectedAccommodation,
    setAvailableRoom,
    booking,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();

    const openEditAccommodation = (row) => {
        bookingApi
            .getAvailableRooms(
                authInfo.tenant.id,
                row.check_in_date,
                row.check_out_date,
                row.id
            )
            .then(
                (data) => {
                    setAvailableRoom(data.data.get_available_rooms);
                    setSelectedAccommodation(row);
                    setOpenAccommodation(true);
                },
                (error) => {
                    notify(error);
                }
            );
    };

    const openAddAccommodation = () => {
        setSelectedAccommodation(null);
        setOpenAccommodation(true);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Accommodations
                            {booking?.status != "Completed" &&
                                booking?.status != "Cancelled" ? (
                                <Button
                                    style={{ float: "right", color: "#4f3cc9" }}
                                    variant="outline"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={openAddAccommodation}
                                >
                                    Add
                                </Button>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Rate</TableCell>
                                        <TableCell>Cancellation policy</TableCell>
                                        <TableCell>Check In Date</TableCell>
                                        <TableCell>Check Out Date</TableCell>
                                        <TableCell>Room</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            booking?.accommodations.map((row) => (
                                                <FormWithRedirect
                                                    onSubmit={() => { }}
                                                    render={(formProps) => (
                                                        <TableRow>
                                                            <TableCell>{row.room_type.name}</TableCell>
                                                            <TableCell>
                                                                {row.room_type_rate.room_rate.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    row.room_type_rate.room_rate
                                                                        .cancellation_policy.name
                                                                }
                                                            </TableCell>
                                                            <TableCell>{row.check_in_date}</TableCell>
                                                            <TableCell>{row.check_out_date}</TableCell>
                                                            <TableCell>{row.room?.name}</TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    label={row.e_accommodation_status.comment}
                                                                    style={{
                                                                        color: "white",
                                                                        background: accommodationStatus.getColor(
                                                                            row.e_accommodation_status.id
                                                                        ),
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.status != "Checked_Out" &&
                                                                    row.status != "Cancelled" ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<EditIcon />}
                                                                        onClick={() => openEditAccommodation(row)}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                ) : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                />
                                            ))
                                        }
                                    </FormDataConsumer>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const DepositInfo = ({
    setOpenDeposit,
    setOpenRefund,
    setSelectedDeposit,
    setSelectedRefund,
    setSelectedRefundDeposit,
    setSelectedRefundOrder,
    selectedDeposit,
    booking,
    refreshBooking,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [openCancel, setOpenCancel] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleOpenCancel = (data) => {
        setSelectedDeposit(data);
        setOpenCancel(true);
    };

    const handleCloseCancel = () => {
        setSelectedDeposit(null);
        setOpenCancel(false);
    };

    const openEditDeposit = (data) => {
        setSelectedDeposit(data);
        setOpenDeposit(true);
    };

    const openAddDeposit = () => {
        setSelectedDeposit(null);
        setOpenDeposit(true);
    };

    const openAddRefund = (data) => {
        setSelectedRefund(null);
        setSelectedRefundDeposit(data);
        setSelectedRefundOrder(null);
        setOpenRefund(true);
    };

    const cancelDeposit = () => {
        setSaving(true);
        let data = selectedDeposit;
        //call payment service
        var paymentrequest = {
            transactionId: data.payment_transaction_id,
        };
        paymentApi.midtransCancel(paymentrequest).then(
            (paymentData) => {
                dataProvider
                    .update("deposits", { id: data.id, data: { status: "Cancelled" } })
                    .then(({ data }) => {
                        setOpenCancel(false);
                        refreshBooking();
                        setSaving(false);
                    })
                    .catch((error) => {
                        setOpenCancel(false);
                        setSaving(false);
                        notify(error);
                    });
            },
            (error) => {
                setOpenCancel(false);
                notify("Fail to cancel payment");
                setSaving(false);
            }
        );
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Deposits
                            {booking?.status != "Completed" &&
                                booking?.status != "Cancelled" ? (
                                <Button
                                    style={{ float: "right", color: "#4f3cc9" }}
                                    variant="outline"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={openAddDeposit}
                                >
                                    Add
                                </Button>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Deposit No</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Received Amount</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Used Amount</TableCell>
                                        <TableCell>Refunded Amount</TableCell>
                                        <TableCell>Remaining Amount</TableCell>
                                        <TableCell>Deposit Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            booking?.deposits.map((row) => (
                                                <FormWithRedirect
                                                    onSubmit={() => { }}
                                                    render={(formProps) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {formatter.depositNo(row.deposit_no)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.e_deposit_type.comment}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.received_amount,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.amount,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.invoice_payments.reduce(
                                                                        (total, obj) => obj.amount + total,
                                                                        0
                                                                    ),
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.refunds
                                                                        .filter((item) => {
                                                                            return item.status != "Cancelled";
                                                                        })
                                                                        .reduce(
                                                                            (total, obj) => obj.amount + total,
                                                                            0
                                                                        ),
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.amount -
                                                                    row.invoice_payments.reduce(
                                                                        (total, obj) => obj.amount + total,
                                                                        0
                                                                    ) -
                                                                    row.refunds
                                                                        .filter((item) => {
                                                                            return item.status != "Cancelled";
                                                                        })
                                                                        .reduce(
                                                                            (total, obj) => obj.amount + total,
                                                                            0
                                                                        ),
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.created_at).format(
                                                                    "DD MMM YYYY hh:mm:ss A"
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    label={row.e_deposit_status.comment}
                                                                    style={{
                                                                        color: "white",
                                                                        background: depositStatus.getColor(
                                                                            row.e_deposit_status.id
                                                                        ),
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.status == "Preauthorized" ? (
                                                                    <Button
                                                                        style={{ color: "red" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<CloseIcon />}
                                                                        onClick={() => handleOpenCancel(row)}
                                                                    >
                                                                        Cancel
                                                                    </Button>
                                                                ) : null}
                                                                {row.status == "Paid" &&
                                                                    row.amount -
                                                                    row.invoice_payments.reduce(
                                                                        (total, obj) => obj.amount + total,
                                                                        0
                                                                    ) -
                                                                    row.refunds
                                                                        .filter((item) => {
                                                                            return item.status != "Cancelled";
                                                                        })
                                                                        .reduce(
                                                                            (total, obj) => obj.amount + total,
                                                                            0
                                                                        ) >
                                                                    0 ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<MoneyOffIcon />}
                                                                        onClick={() => openAddRefund(row)}
                                                                    >
                                                                        Refund
                                                                    </Button>
                                                                ) : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                />
                                            ))
                                        }
                                    </FormDataConsumer>
                                    <Dialog
                                        open={openCancel}
                                        onClose={handleCloseCancel}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Confirm Cancel?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Please confirm to cancel
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => handleCloseCancel()}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => cancelDeposit()}
                                                color="primary"
                                                autoFocus
                                                disabled={saving}
                                                startIcon={
                                                    saving ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            style={{ width: 20, height: 20 }}
                                                        />
                                                    ) : null
                                                }
                                            >
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const RefundInfo = ({ setOpenRefund, setSelectedRefund, booking }) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();

    const openEditRefund = (data) => {
        setSelectedRefund(data);
        setOpenRefund(true);
    };

    const openAddRefund = () => {
        setSelectedRefund(null);
        setOpenRefund(true);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Refunds
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Refund No</TableCell>
                                        <TableCell>Reference</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Amount</TableCell>
                                        <TableCell>Requested Date</TableCell>
                                        <TableCell>Completed Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            booking?.refunds.map((row) => (
                                                <FormWithRedirect
                                                    onSubmit={() => { }}
                                                    render={(formProps) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {formatter.refundNo(row.refund_no)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.order != null
                                                                    ? "Order - " +
                                                                    formatter.orderNo(row.order.order_no)
                                                                    : "Deposit - " +
                                                                    formatter.depositNo(row.deposit.deposit_no)}
                                                            </TableCell>
                                                            <TableCell>{row.e_refund_type.comment}</TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.amount,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.created_at).format(
                                                                    "DD MMM YYYY hh:mm:ss A"
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.completed_at).format(
                                                                    "DD MMM YYYY hh:mm:ss A"
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    label={row.e_refund_status.comment}
                                                                    style={{
                                                                        color: "white",
                                                                        background: refundStatus.getColor(
                                                                            row.e_refund_status.id
                                                                        ),
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.status == "New" ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<EditIcon />}
                                                                        onClick={() => openEditRefund(row)}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                ) : null}
                                                                {row.status != "New" ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<VisibilityIcon />}
                                                                        onClick={() => openEditRefund(row)}
                                                                    >
                                                                        View
                                                                    </Button>
                                                                ) : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                />
                                            ))
                                        }
                                    </FormDataConsumer>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const OrderInfo = ({
    setOpenOrder,
    setOpenRefund,
    setSelectedOrder,
    setSelectedRefund,
    setSelectedRefundDeposit,
    setSelectedRefundOrder,
    booking,
    subscriptionSummary = (JSON.parse(localStorage.getItem("auth"))).merchantCore.subscriptionSummary
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();

    const openEditOrder = (data) => {
        setSelectedOrder(data);
        setOpenOrder(true);
    };

    const openAddOrder = () => {
        setSelectedOrder(null);
        setOpenOrder(true);
    };

    const openAddRefund = (data) => {
        setSelectedRefund(null);
        setSelectedRefundDeposit(null);
        setSelectedRefundOrder(data);
        setOpenRefund(true);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Orders
                            {subscriptionSummary && subscriptionSummary.service ?
                                ((booking?.status != "Completed" &&
                                    booking?.status != "Cancelled" &&
                                    booking?.accommodations
                                        .find(x => x.status === "Checked_In") !== undefined) ? (
                                    <Button
                                        style={{ float: "right", color: "#4f3cc9" }}
                                        variant="outline"
                                        color="primary"
                                        startIcon={<AddIcon />}
                                        onClick={openAddOrder}
                                    >
                                        Add
                                    </Button>
                                ) : null)
                                : null}

                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Order No</TableCell>
                                        <TableCell>Room</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Service Charge</TableCell>
                                        <TableCell>Total Price</TableCell>
                                        <TableCell>Refunded Amount</TableCell>
                                        <TableCell>Order Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            booking?.orders.map((row) => (
                                                <FormWithRedirect
                                                    onSubmit={() => { }}
                                                    render={(formProps) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {formatter.orderNo(row.order_no)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.accommodation?.room?.name}
                                                            </TableCell>
                                                            <TableCell>{row.description}</TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.price * row.service_charge,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.total_price,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.refunds
                                                                        .filter((item) => {
                                                                            return item.status != "Cancelled";
                                                                        })
                                                                        .reduce(
                                                                            (total, obj) => obj.amount + total,
                                                                            0
                                                                        ),
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.created_at).format(
                                                                    "DD MMM YYYY hh:mm:ss A"
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    label={row.e_order_status.comment}
                                                                    style={{
                                                                        color: "white",
                                                                        background: orderStatus.getColor(
                                                                            row.e_order_status.id
                                                                        ),
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.status == "New" &&
                                                                    (row.order_type == "Service" ||
                                                                        row.order_type == "Bleudine") ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<EditIcon />}
                                                                        onClick={() => openEditOrder(row)}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                ) : null}
                                                                {row.status == "Paid" ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<MoneyOffIcon />}
                                                                        onClick={() => openAddRefund(row)}
                                                                    >
                                                                        Refund
                                                                    </Button>
                                                                ) : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                />
                                            ))
                                        }
                                    </FormDataConsumer>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const InvoiceInfo = ({
    setOpenInvoice,
    setSelectedInvoice,
    setSelectedOrderIds,
    booking,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();

    const openEditInvoice = (data) => {
        setSelectedInvoice(data);
        setOpenInvoice(true);
        setSelectedOrderIds(
            data.invoice_items.map((ord) => {
                return ord.order_id;
            })
        );
    };

    const openAddInvoice = () => {
        setSelectedInvoice(null);
        setOpenInvoice(true);
        setSelectedOrderIds([]);
    };

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.textBold} gutterBottom>
                            Invoices
                            {booking?.status != "Completed" &&
                                booking?.status != "Cancelled" ? (
                                <Button
                                    style={{ float: "right", color: "#4f3cc9" }}
                                    variant="outline"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    onClick={openAddInvoice}
                                >
                                    Add
                                </Button>
                            ) : null}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer component={Paper}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Invoice No</TableCell>
                                        <TableCell>Subtotal</TableCell>
                                        <TableCell>Tax</TableCell>
                                        <TableCell>Total</TableCell>
                                        <TableCell>Invoice Date</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            booking?.invoices.map((row) => (
                                                <FormWithRedirect
                                                    onSubmit={() => { }}
                                                    render={(formProps) => (
                                                        <TableRow>
                                                            <TableCell>
                                                                {formatter.invoiceNo(row.invoice_no)}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.subtotal,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.tax,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {formatter.formatMoney(
                                                                    row.total,
                                                                    authInfo.tenant.locale.currency_code,
                                                                    authInfo.tenant.locale.precision
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                {moment(row.created_at).format(
                                                                    "DD MMM YYYY hh:mm:ss A"
                                                                )}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Chip
                                                                    label={row.e_invoice_status.comment}
                                                                    style={{
                                                                        color: "white",
                                                                        background: invoiceStatus.getColor(
                                                                            row.e_invoice_status.id
                                                                        ),
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                {row.status != "Paid" &&
                                                                    row.status != "Cancelled" ? (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<EditIcon />}
                                                                        onClick={() => openEditInvoice(row)}
                                                                    >
                                                                        Edit
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        style={{ color: "#4f3cc9" }}
                                                                        variant="outline"
                                                                        color="primary"
                                                                        startIcon={<VisibilityIcon />}
                                                                        onClick={() => openEditInvoice(row)}
                                                                    >
                                                                        View
                                                                    </Button>
                                                                )}
                                                            </TableCell>
                                                        </TableRow>
                                                    )}
                                                />
                                            ))
                                        }
                                    </FormDataConsumer>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

const AccommodationModal = ({
    openAccommodation,
    setOpenAccommodation,
    selectedAccommodation,
    availableRoomType,
    setAvailableRoomType,
    availableRoom,
    setAvailableRoom,
    booking,
    refreshBooking,
    roomTypes,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [openCancel, setOpenCancel] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleOpenCancel = () => {
        setOpenCancel(true);
    };

    const handleCloseCancel = () => {
        setOpenCancel(false);
    };

    function updateAvailableRoomType(checkInDate, checkOutDate) {
        if (
            checkInDate != null &&
            checkInDate != "" &&
            checkOutDate != null &&
            checkOutDate != ""
        ) {
            bookingApi
                .getAvailableRoomTypes(
                    authInfo.tenant.id,
                    checkInDate,
                    checkOutDate,
                    -1,
                    -1
                )
                .then(
                    (data) => {
                        setAvailableRoomType(data.data.get_available_room_types);
                    },
                    (error) => {
                        notify(error);
                    }
                );
        } else {
            setAvailableRoomType([]);
        }
    }

    function updateAvailableRoom(checkInDate, checkOutDate, roomTypeId) {
        if (
            checkInDate != null &&
            checkInDate != "" &&
            checkOutDate != null &&
            checkOutDate != "" &&
            roomTypeId != "" &&
            roomTypeId != null
        ) {
            bookingApi
                .getAvailableRooms(authInfo.tenant.id, checkInDate, checkOutDate, null)
                .then(
                    (data) => {
                        setAvailableRoom(data.data.get_available_rooms);
                    },
                    (error) => {
                        notify(error);
                    }
                );
        } else {
            setAvailableRoom([]);
        }
    }

    function parseDate(str) {
        var mdy = str.split("-");
        return new Date(mdy[0], mdy[1] - 1, mdy[2]);
    }

    function datediff(first, second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24));
    }

    const addAccommodation = (formData) => {
        setSaving(true);
        var request = {
            tenant_id: authInfo.tenant.id,
            check_in_date: formData.check_in_date,
            check_out_date: formData.check_out_date,
            room_type_id: formData.room_type_id,
            room_type_rate_id: formData.room_type_rate_id,
            room_id: formData.room_id,
            booking_id: booking.id,
            status: formData.room_id != null ? "Confirmed" : "New",
        };
        dataProvider
            .create("accommodations", { data: request })
            .then(({ data }) => {
                //add order
                let room_type = roomTypes.filter((item) => {
                    return item.id == formData.room_type_id;
                })[0];
                let room_type_rate = room_type.room_type_rates.filter((item) => {
                    return item.id == formData.room_type_rate_id;
                })[0];


                if (room_type_rate.price != 0) {
                    let price = computeRoomChargesForAccommodation({ check_in_date: formData.check_in_date, check_out_date: formData.check_out_date }, room_type_rate)
                    let order_request = {
                        tenant_id: authInfo.tenant.id,
                        accommodation_id: data.id,
                        service_id: null,
                        qty: datediff(
                            parseDate(formData.check_in_date),
                            parseDate(formData.check_out_date)
                        ),
                        price: room_type_rate.price,
                        service_charge: room_type_rate.service_charge,
                        total_price: price + price * room_type_rate.service_charge,
                        booking_id: booking.id,
                        description:
                            room_type.name +
                            " (" +
                            formData.check_in_date +
                            " - " +
                            formData.check_out_date +
                            ")",
                        order_type: "Accommodation",
                        status: "New",
                    };
                    dataProvider.create("orders", { data: order_request });
                }

                //update booking if got changes
                if (formData.room_id != null && booking.status == "New") {
                    dataProvider.update("bookings", {
                        id: booking.id,
                        data: { status: "Confirmed" },
                    });
                }

                refreshBooking();
                setOpenAccommodation(false);
                setSaving(false);
                notify("Accommodation created");
            })
            .catch((error) => {
                setSaving(false);
                notify(error);
            });
    };

    const updateAccommodation = (process, formData) => {
        setSaving(true);
        //check valid
        var isValid = true;
        var request = {};
        if (process == "update") {
            request.check_in_date = formData.check_in_date;
            request.check_out_date = formData.check_out_date;
            request.room_type_id = formData.room_type_id;
            request.room_type_rate_id = formData.room_type_rate_id;
            request.room_id = formData.room_id;
            request.status =
                selectedAccommodation.status == "New" && formData.room_id != null
                    ? "Confirmed"
                    : selectedAccommodation.status;
        } else if (process == "checkin") {
            if (!formData.room_id) {
                notify("Please select a room to check in");
                isValid = false;
            }
            request.check_in_date = formData.check_in_date;
            request.check_out_date = formData.check_out_date;
            request.room_type_id = formData.room_type_id;
            request.room_type_rate_id = formData.room_type_rate_id;
            request.room_id = formData.room_id;
            request.status = "Checked_In";
        } else if (process == "checkout") {
            if (
                booking.orders.filter(
                    (record) =>
                        record.accommodation_id == selectedAccommodation.id &&
                        (record.status == "New" || record.status == "Invoiced")
                ).length > 0
            ) {
                notify("Please invoice & paid all related order before check out.");
                isValid = false;
            }

            request.check_out_date = formData.check_out_date;
            request.status = "Checked_Out";
        } else if (process == "cancel") {
            request.status = "Cancelled";
        }

        if (isValid) {
            dataProvider
                .update("accommodations", {
                    id: selectedAccommodation.id,
                    data: { ...request },
                })
                .then(({ data }) => {
                    //update booking status
                    let bookingStatus = "New";
                    if (
                        data.status == "Confirmed" ||
                        booking.accommodations.filter(
                            (record) =>
                                record.id != selectedAccommodation.id &&
                                record.status == "Confirmed"
                        ).length > 0
                    ) {
                        //from new to confirm
                        bookingStatus = "Confirmed";
                    }
                    if (
                        data.status == "Checked_In" ||
                        booking.accommodations.filter(
                            (record) =>
                                record.id != selectedAccommodation.id &&
                                record.status == "Checked_In"
                        ).length > 0
                    ) {
                        //from confirm to checkin
                        bookingStatus = "Checked_In";
                    }
                    if (
                        data.status == "Cancalled" &&
                        booking.accommodations.filter(
                            (record) =>
                                record.id != selectedAccommodation.id &&
                                record.status == "Cancelled"
                        ).length ==
                        data.accommodations.length - 1
                    ) {
                        //if all accommodation is cancel, to cancel
                        bookingStatus = "Cancelled";
                    }
                    if (
                        (data.status == "Cancalled" || data.status == "Checked_Out") &&
                        booking.accommodations.filter(
                            (record) =>
                                record.id != selectedAccommodation.id &&
                                (record.status == "New" ||
                                    record.status == "Checked_In" ||
                                    record.status == "Confirmed")
                        ).length == 0
                    ) {
                        //if all is end, to complete
                        bookingStatus = "Completed";
                    }

                    if (booking.status != bookingStatus) {
                        dataProvider
                            .update("bookings", {
                                id: booking.id,
                                data: { status: bookingStatus },
                            })
                            .then(({ data }) => {
                                refreshBooking();
                            });
                    }

                    //update order if got changes
                    let order = booking.orders.filter((item) => {
                        return (
                            item.accommodation_id == selectedAccommodation.id &&
                            item.order_type == "Accommodation" &&
                            item.status == "New"
                        );
                    });
                    if (
                        order.length > 0 &&
                        (selectedAccommodation.check_in_date != formData.check_in_date ||
                            selectedAccommodation.check_out_date != formData.check_out_date ||
                            selectedAccommodation.room_type_id != formData.room_type_id ||
                            selectedAccommodation.room_type_rate_id !=
                            formData.room_type_rate_id)
                    ) {
                        let room_type = roomTypes.filter((item) => {
                            return item.id == formData.room_type_id;
                        })[0];
                        let room_type_rate = room_type.room_type_rates.filter((item) => {
                            return item.id == formData.room_type_rate_id;
                        })[0];

                        let price = computeRoomChargesForAccommodation({ check_in_date: formData.check_in_date, check_out_date: formData.check_out_date }, room_type_rate)

                        let order_request = {
                            qty: datediff(
                                parseDate(formData.check_in_date),
                                parseDate(formData.check_out_date)
                            ),
                            price: room_type_rate.price,
                            service_charge: room_type_rate.service_charge,
                            total_price: price + price * room_type_rate.service_charge,
                            description:
                                room_type.name +
                                " (" +
                                formData.check_in_date +
                                " - " +
                                formData.check_out_date +
                                ")",
                        };
                        dataProvider
                            .update("orders", { id: order[0].id, data: { ...order_request } })
                            .then(({ data }) => {
                                refreshBooking();
                            });
                    }

                    //cancel order if accommodation cancel
                    if (order.length > 0 && request.status == "Cancelled") {
                        let order_request = {
                            status: "Cancelled",
                        };
                        dataProvider
                            .update("orders", { id: order[0].id, data: { ...order_request } })
                            .then(({ data }) => {
                                refreshBooking();
                            });
                    }

                    //update room dirty
                    if (data.status == "Checked_Out") {
                        dataProvider
                            .update("rooms", {
                                id: data.room_id,
                                data: { clean_status: "Dirty" },
                            })
                            .then(({ data }) => { });
                    }

                    setOpenAccommodation(false);
                    setOpenCancel(false);
                    refreshBooking();
                    notify("Accommodation updated");
                    setSaving(false);
                })
                .catch((error) => {
                    notify(error);
                    setOpenCancel(false);
                    setSaving(false);
                });
        } else {
            setSaving(false);
        }
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenAccommodation(false)}
            aria-labelledby="customized-dialog-title"
            open={openAccommodation}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenAccommodation(false)}
            >
                {selectedAccommodation != null
                    ? "Accommodation " +
                    (selectedAccommodation.room != null
                        ? selectedAccommodation.room.name
                        : "-")
                    : "Add Accommodation"}
            </DialogTitle>
            <FormWithRedirect
                onSubmit={() => { }}
                record={selectedAccommodation}
                render={(formProps) => (
                    <div>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <DateInput
                                                className={classes.formInput}
                                                fullWidth
                                                source="check_in_date"
                                                label="Check In Date"
                                                defaultValue={moment(today).format("YYYY-MM-DD")}
                                                onChange={(event) =>
                                                    updateAvailableRoomType(
                                                        event.target.value,
                                                        formData.check_out_date
                                                    )
                                                }
                                                validate={[
                                                    required(),
                                                    minValue(
                                                        !selectedAccommodation
                                                            ? moment(new Date()).format("YYYY-MM-DD")
                                                            : null
                                                    ),
                                                ]}
                                                disabled={selectedAccommodation}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (
                                            <DateInput
                                                className={classes.formInput}
                                                fullWidth
                                                source="check_out_date"
                                                label="Check Out Date"
                                                defaultValue={moment(tomorrow).format("YYYY-MM-DD")}
                                                onChange={(event) =>
                                                    updateAvailableRoomType(
                                                        formData.check_in_date,
                                                        event.target.value
                                                    )
                                                }
                                                disabled={
                                                    selectedAccommodation?.status == "Checked_Out"
                                                }
                                                validate={[
                                                    required(),
                                                    maxValue(
                                                        moment(
                                                            new Date(selectedAccommodation?.check_out_date)
                                                        ).format("YYYY-MM-DD")
                                                    ),
                                                    minValue(
                                                        moment(
                                                            new Date(formData.check_in_date).setDate(
                                                                new Date(formData.check_in_date).getDate() + 1
                                                            )
                                                        ).format("YYYY-MM-DD")
                                                    ),
                                                ]}
                                            />
                                        )}
                                    </FormDataConsumer>
                                </Grid>

                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData.check_in_date != "" &&
                                                formData.check_in_date != null &&
                                                formData.check_out_date != "" &&
                                                formData.check_out_date != null ? (
                                                <SelectInput
                                                    className={classes.formInput}
                                                    fullWidth
                                                    label="Room Type"
                                                    source="room_type_id"
                                                    onChange={(event) =>
                                                        updateAvailableRoom(
                                                            formData.check_in_date,
                                                            formData.check_out_date,
                                                            event.target.value
                                                        )
                                                    }
                                                    choices={
                                                        selectedAccommodation != null
                                                            ? roomTypes.map(function (item) {
                                                                return { id: item.id, name: item.name };
                                                            })
                                                            : availableRoomType.map(function (item) {
                                                                return { id: item.id, name: item.name };
                                                            })
                                                    }
                                                    options={{
                                                        InputProps: { autoComplete: "no" },
                                                    }}
                                                    validate={[required()]}
                                                    disabled={selectedAccommodation}
                                                />
                                            ) : null
                                        }
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} sm={6} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData.room_type_id ? (
                                                <SelectInput
                                                    className={classes.formInput}
                                                    fullWidth
                                                    label="Room Type Rate"
                                                    source="room_type_rate_id"
                                                    choices={roomTypes
                                                        .filter(
                                                            (fitem) => fitem.id === formData.room_type_id
                                                        )[0]
                                                        .room_type_rates.map((item) => {
                                                            return {
                                                                id: item.id,
                                                                name: item.room_rate.name,
                                                            };
                                                        })}
                                                    optionText="name"
                                                    options={{
                                                        InputProps: { autoComplete: "no" },
                                                    }}
                                                    validate={[required()]}
                                                    disabled={selectedAccommodation}
                                                />
                                            ) : null
                                        }
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) =>
                                            formData.room_type_id && formData.room_type_rate_id ? (
                                                <SelectInput
                                                    className={classes.formInput}
                                                    fullWidth
                                                    label="Room"
                                                    source="room_id"
                                                    choices={availableRoom
                                                        .filter(
                                                            (fitem) =>
                                                                fitem.room_type_id === formData.room_type_id
                                                        )
                                                        .map((item) => {
                                                            return { id: item.id, name: item.name };
                                                        })}
                                                    optionText="name"
                                                    options={{
                                                        InputProps: { autoComplete: "no" },
                                                    }}
                                                    disabled={
                                                        selectedAccommodation?.status == "Checked_In" ||
                                                        selectedAccommodation?.status == "Checked_Out" ||
                                                        selectedAccommodation?.status == "Cancelled"
                                                    }
                                                />
                                            ) : null
                                        }
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Additional Adult</Typography>
                                </Grid>

                                <Grid item xs={6} className={classes.formGrid}>
                                    <NumberInput className={classes.formInput}
                                        fullWidth
                                        label="Additional Adult"
                                        source="additional_adult" defaultValue={0} />
                                </Grid>

                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Cancellation Policy</Typography>
                                </Grid>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        var selected_room_type = roomTypes.filter(
                                            (fitem) => fitem.id === formData.room_type_id
                                        )[0];

                                        var selected_room_type_rate = selected_room_type
                                            ? selected_room_type.room_type_rates.filter(
                                                (fitem) => fitem.id === formData.room_type_rate_id
                                            )[0]
                                            : null;

                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                className={classes.formGrid}
                                                align="right"
                                            >
                                                {selected_room_type_rate
                                                    ? selected_room_type_rate.room_rate
                                                        .cancellation_policy.name
                                                    : "-"}
                                            </Grid>
                                        );
                                    }}
                                </FormDataConsumer>
                                {
                                    selectedAccommodation ? (
                                        <Grid item xs={6} className={classes.formGrid}>
                                            <Typography gutterBottom>Status</Typography>
                                        </Grid>
                                    ) : null
                                }
                                {
                                    selectedAccommodation ? (
                                        <Grid
                                            item
                                            xs={6}
                                            className={classes.formGrid}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Chip
                                                label={
                                                    selectedAccommodation?.e_accommodation_status.comment
                                                }
                                                style={{
                                                    color: "white",
                                                    background: accommodationStatus.getColor(
                                                        selectedAccommodation?.status
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    ) : null
                                }
                            </Grid >
                        </DialogContent >
                        <DialogActions>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedAccommodation == null ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            className={classes.formInput}
                                            onSave={() => addAccommodation(formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedAccommodation?.status == "New" ||
                                        selectedAccommodation?.status == "Confirmed" ||
                                        selectedAccommodation?.status == "Checked_In" ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            label="Update"
                                            icon={<SaveIcon />}
                                            className={classes.formInput}
                                            onSave={() => updateAccommodation("update", formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedAccommodation?.status == "New" ||
                                        selectedAccommodation?.status == "Confirmed" ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            label="Check In"
                                            icon={<ArrowDownwardIcon />}
                                            className={classes.formInput}
                                            onSave={() => updateAccommodation("checkin", formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedAccommodation?.status == "New" ||
                                        selectedAccommodation?.status == "Confirmed" ? (
                                        <Button
                                            style={{ backgroundColor: "red", color: "white" }}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<ClearIcon />}
                                            onClick={handleOpenCancel}
                                        >
                                            Cancel Accommodation
                                        </Button>
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <Dialog
                                        open={openCancel}
                                        onClose={handleCloseCancel}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Confirm Cancel?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Please confirm to cancel
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => handleCloseCancel()}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => updateAccommodation("cancel", formData)}
                                                color="primary"
                                                autoFocus
                                                disabled={saving}
                                                startIcon={
                                                    saving ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            style={{ width: 20, height: 20 }}
                                                        />
                                                    ) : null
                                                }
                                            >
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedAccommodation?.status == "Checked_In" ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            label="Check Out"
                                            icon={<ArrowUpwardIcon />}
                                            className={classes.formInput}
                                            saving={saving}
                                            disabled={saving}
                                            onSave={() => updateAccommodation("checkout", formData)}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                        </DialogActions>
                    </div >
                )}
            />
        </Dialog >
    );
};

const DepositModal = ({
    openDeposit,
    setOpenDeposit,
    selectedDeposit,
    booking,
    refreshBooking,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [saving, setSaving] = React.useState(false);

    const removeDeposit = (id) => {
        dataProvider
            .delete("deposits", { id: id })
            .then(({ data }) => { })
            .catch((error) => {
                notify(error);
            });
    };

    const addDeposit = (formData) => {
        let tenantCurrencyCode = authInfo.tenant.locale.currency_code;
        let midtransCurrency = "IDR";
        let midtransAmount = formData.amount;

        fetch(
            `https://api.exchangerate.host/convert?from=${tenantCurrencyCode}&to=${midtransCurrency}&amount=${formData.amount}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    midtransAmount = result.result;

                    setSaving(true);
                    var request = {
                        tenant_id: authInfo.tenant.id,
                        booking_id: booking.id,
                        type: formData.deposit_type,
                        amount: formData.amount,
                        received_currency: formData.deposit_type == "Cash" ? tenantCurrencyCode : midtransCurrency,
                        received_amount: formData.deposit_type == "Cash" ? formData.amount : midtransAmount,
                        status: formData.deposit_type == "Cash" ? "Paid" : "Preauthorized",
                    };

                    dataProvider
                        .create("deposits", { data: request })
                        .then(({ data }) => {
                            if (formData.deposit_type == "Credit_Card") {
                                var card = {
                                    card_number: formData.card_number,
                                    card_cvv: formData.card_cvv,
                                    card_exp_month: formData.card_exp_month,
                                    card_exp_year: formData.card_exp_year,
                                };

                                var options = {
                                    onSuccess: function (response) {
                                        //call payment service
                                        var paymentrequest = {
                                            tokenId: response.token_id,
                                            amount: midtransAmount,
                                            orderId: formatter.depositNo(data.deposit_no),
                                            referenceType: paymentReferenceType.PMSDeposit,
                                            referenceId: data.id,
                                            firstName: booking.guest.first_name,
                                            lastName: booking.guest.last_name,
                                            email: booking.guest.email,
                                            phoneNumber: booking.guest.phone_no,
                                            isPreauthorize: true,
                                        };
                                        paymentApi.midtransChargeWithToken(paymentrequest).then(
                                            (paymentData) => {
                                                if (paymentData.statusCode == "200") {
                                                    dataProvider
                                                        .update("deposits", {
                                                            id: data.id,
                                                            data: {
                                                                payment_transaction_id: paymentData.transactionId,
                                                            },
                                                        })
                                                        .then((uData) => {
                                                            setOpenDeposit(false);
                                                            refreshBooking();
                                                            notify("Deposit created");
                                                            setSaving(false);
                                                        })
                                                        .catch((error) => {
                                                            notify(error);
                                                        });
                                                } else {
                                                    notify("Fail to charge payment");
                                                    removeDeposit(data.id);
                                                    setSaving(false);
                                                }
                                            },
                                            (error) => {
                                                notify("Fail to charge payment");
                                                removeDeposit(data.id);
                                                setSaving(false);
                                            }
                                        );
                                    },
                                    onFailure: function (response) {
                                        notify("Fail to authenticate payment");
                                        removeDeposit(data.id);
                                        setSaving(false);
                                    },
                                };
                                window.MidtransNew3ds.getCardToken(card, options);
                            } else {
                                setOpenDeposit(false);
                                refreshBooking();
                                notify("Deposit created");
                                setSaving(false);
                            }
                        })
                        .catch((error) => {
                            setSaving(false);
                            notify(error);
                        })
                },
                (error) => { }
            );


    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenDeposit(false)}
            aria-labelledby="customized-dialog-title"
            open={openDeposit}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenDeposit(false)}
            >
                {selectedDeposit != null
                    ? "Deposit " + formatter.refundNo(selectedDeposit.deposit_no)
                    : "Add Deposit"}
            </DialogTitle>
            <FormWithRedirect
                onSubmit={() => { }}
                record={selectedDeposit}
                render={(formProps) => (
                    <div>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Deposit Type"
                                        source="deposit_type"
                                        reference="e_deposit_types"
                                        validate={[required()]}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.deposit_type == "Credit_Card") {
                                                return (
                                                    <TextInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        type="number"
                                                        source="card_number"
                                                        label="Card Number"
                                                        validate={[required()]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.deposit_type == "Credit_Card") {
                                                return (
                                                    <TextInput
                                                        type="number"
                                                        className={classes.formInput}
                                                        fullWidth
                                                        source="card_cvv"
                                                        label="CVV"
                                                        validate={[required()]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.deposit_type == "Credit_Card") {
                                                return (
                                                    <SelectInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        source="card_exp_month"
                                                        label="Expiry Month"
                                                        validate={[required()]}
                                                        choices={[
                                                            { id: "01", name: "January" },
                                                            { id: "02", name: "February" },
                                                            { id: "03", name: "March" },
                                                            { id: "04", name: "April" },
                                                            { id: "05", name: "May" },
                                                            { id: "06", name: "June" },
                                                            { id: "07", name: "July" },
                                                            { id: "08", name: "August" },
                                                            { id: "09", name: "September" },
                                                            { id: "10", name: "October" },
                                                            { id: "11", name: "November" },
                                                            { id: "12", name: "December" },
                                                        ]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.deposit_type == "Credit_Card") {
                                                return (
                                                    <SelectInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        source="card_exp_year"
                                                        label="Expiry Year"
                                                        validate={[required()]}
                                                        choices={[
                                                            { id: "2021", name: "2021" },
                                                            { id: "2022", name: "2022" },
                                                            { id: "2023", name: "2023" },
                                                            { id: "2024", name: "2024" },
                                                            { id: "2025", name: "2025" },
                                                        ]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <NumberInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Amount"
                                        source="amount"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {authInfo.tenant.locale.currency_code}
                                                </InputAdornment>
                                            ),
                                        }}
                                        validate={[required(), number(), minValue(1)]}
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedDeposit == null ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            className={classes.formInput}
                                            onSave={() => addDeposit(formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                        </DialogActions>
                    </div>
                )}
            />
        </Dialog>
    );
};

const RefundModal = ({
    openRefund,
    setOpenRefund,
    selectedRefund,
    selectedRefundDeposit,
    selectedRefundOrder,
    booking,
    refreshBooking,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [openCancel, setOpenCancel] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleOpenCancel = () => {
        setOpenCancel(true);
    };

    const handleCloseCancel = () => {
        setOpenCancel(false);
    };

    const addRefund = (formData) => {
        setSaving(true);
        var request = {
            tenant_id: authInfo.tenant.id,
            booking_id: booking.id,
            type: formData.type,
            amount: formData.amount,
            deposit_id:
                selectedRefundDeposit != null ? selectedRefundDeposit.id : null,
            order_id: selectedRefundOrder != null ? selectedRefundOrder.id : null,
            status: formData.type == "Bank_Transfer" ? "New" : "Completed",
            beneficiary_name:
                formData.type == "Bank_Transfer" ? formData.beneficiary_name : null,
            beneficiary_bank_name:
                formData.type == "Bank_Transfer"
                    ? formData.beneficiary_bank_name
                    : null,
            beneficiary_acc_no:
                formData.type == "Bank_Transfer" ? formData.beneficiary_acc_no : null,
            remarks: formData.remarks,
        };

        dataProvider
            .create("refunds", { data: request })
            .then(({ data }) => {
                setOpenRefund(false);
                refreshBooking();
                notify("Refund created");
                setSaving(false);
            })
            .catch((error) => {
                notify(error);
                setSaving(false);
            });
    };

    const updateRefund = (process, formData) => {
        setSaving(true);
        var request = {};
        if (process == "complete") {
            request.completed_at = new Date();
            request.remarks = formData.remarks;
            request.status = "Completed";
        } else if (process == "cancel") {
            request.remarks = formData.remarks;
            request.status = "Cancelled";
        }

        dataProvider
            .update("refunds", { id: selectedRefund.id, data: { ...request } })
            .then(({ data }) => {
                setOpenRefund(false);
                setOpenCancel(false);
                refreshBooking();
                notify("Refund updated");
                setSaving(false);
            })
            .catch((error) => {
                setOpenCancel(false);
                notify(error);
                setSaving(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenRefund(false)}
            aria-labelledby="customized-dialog-title"
            open={openRefund}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenRefund(false)}
            >
                {selectedRefund != null
                    ? "Refund " + formatter.refundNo(selectedRefund.refund_no)
                    : "Add Refund"}
            </DialogTitle>
            <FormWithRedirect
                onSubmit={() => { }}
                record={selectedRefund}
                render={(formProps) => (
                    <div>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                {selectedRefund == null && selectedRefundDeposit != null ? (
                                    <Grid item xs={12} className={classes.formGrid}>
                                        <Typography
                                            gutterBottom
                                            style={{
                                                fontWeight: "bold",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            Refund Item
                                        </Typography>
                                        <Typography gutterBottom>
                                            Deposit No :{" "}
                                            {formatter.depositNo(selectedRefundDeposit.deposit_no)}
                                        </Typography>
                                        <Typography gutterBottom>
                                            Refundable Amount :{" "}
                                            {formatter.formatMoney(
                                                selectedRefundDeposit.amount -
                                                selectedRefundDeposit.invoice_payments.reduce(
                                                    (total, obj) => obj.amount + total,
                                                    0
                                                ) -
                                                selectedRefundDeposit.refunds
                                                    .filter((item) => {
                                                        return item.status != "Cancelled";
                                                    })
                                                    .reduce((total, obj) => obj.amount + total, 0),
                                                authInfo.tenant.locale.currency_code,
                                                authInfo.tenant.locale.precision
                                            )}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                {selectedRefund == null && selectedRefundOrder != null ? (
                                    <Grid item xs={12} className={classes.formGrid}>
                                        <Typography
                                            gutterBottom
                                            style={{
                                                fontWeight: "bold",
                                                textDecoration: "underline",
                                            }}
                                        >
                                            Refund Item
                                        </Typography>
                                        <Typography gutterBottom>
                                            Order No :{" "}
                                            {formatter.orderNo(selectedRefundOrder.order_no)}
                                        </Typography>
                                        <Typography gutterBottom>
                                            Refundable Amount :{" "}
                                            {formatter.formatMoney(
                                                selectedRefundOrder.total_price -
                                                selectedRefundOrder.refunds
                                                    .filter((item) => {
                                                        return item.status != "Cancelled";
                                                    })
                                                    .reduce((total, obj) => obj.amount + total, 0),
                                                authInfo.tenant.locale.currency_code,
                                                authInfo.tenant.locale.precision
                                            )}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                <Grid item xs={12} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Refund Type"
                                        source="type"
                                        reference="e_refund_types"
                                        validate={[required()]}
                                        disabled={selectedRefund}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.type == "Bank_Transfer") {
                                                return (
                                                    <TextInput
                                                        className={classes.formInput}
                                                        source="beneficiary_name"
                                                        label="Beneficiary Name"
                                                        fullWidth
                                                        validate={[required()]}
                                                        disabled={selectedRefund}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.type == "Bank_Transfer") {
                                                return (
                                                    <TextInput
                                                        className={classes.formInput}
                                                        source="beneficiary_bank_name"
                                                        label="Beneficiary Bank Name"
                                                        fullWidth
                                                        validate={[required()]}
                                                        disabled={selectedRefund}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.type == "Bank_Transfer") {
                                                return (
                                                    <TextInput
                                                        className={classes.formInput}
                                                        source="beneficiary_acc_no"
                                                        label="Beneficiary Account No"
                                                        fullWidth
                                                        validate={[required()]}
                                                        disabled={selectedRefund}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <NumberInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Amount"
                                        source="amount"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    {authInfo.tenant.locale.currency_code}
                                                </InputAdornment>
                                            ),
                                        }}
                                        validate={[
                                            required(),
                                            number(),
                                            minValue(1),
                                            maxValue(
                                                selectedRefund != null
                                                    ? selectedRefund.amount
                                                    : selectedRefundDeposit != null
                                                        ? selectedRefundDeposit.amount -
                                                        selectedRefundDeposit.invoice_payments.reduce(
                                                            (total, obj) => obj.amount + total,
                                                            0
                                                        ) -
                                                        selectedRefundDeposit.refunds
                                                            .filter((item) => {
                                                                return item.status != "Cancelled";
                                                            })
                                                            .reduce((total, obj) => obj.amount + total, 0)
                                                        : selectedRefundOrder != null
                                                            ? selectedRefundOrder.total_price -
                                                            selectedRefundOrder.refunds
                                                                .filter((item) => {
                                                                    return item.status != "Cancelled";
                                                                })
                                                                .reduce((total, obj) => obj.amount + total, 0)
                                                            : 9999999999
                                            ),
                                        ]}
                                        disabled={selectedRefund}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            return (
                                                <TextInput
                                                    className={classes.formInput}
                                                    source="remarks"
                                                    label="Remarks"
                                                    fullWidth
                                                    disabled={
                                                        selectedRefund?.status == "Completed" ||
                                                        selectedRefund?.status == "Cancelled"
                                                    }
                                                />
                                            );
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedRefund == null ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            className={classes.formInput}
                                            onSave={() => addRefund(formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedRefund?.status == "New" ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            label="Complete"
                                            icon={<SaveIcon />}
                                            className={classes.formInput}
                                            onSave={() => updateRefund("complete", formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedRefund?.status == "New" ? (
                                        <Button
                                            style={{ backgroundColor: "red", color: "white" }}
                                            variant="contained"
                                            color="primary"
                                            startIcon={<ClearIcon />}
                                            onClick={handleOpenCancel}
                                        >
                                            Cancel Refund
                                        </Button>
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <Dialog
                                        open={openCancel}
                                        onClose={handleCloseCancel}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Confirm Cancel?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Please confirm to cancel
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => handleCloseCancel()}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => updateRefund("cancel", formData)}
                                                color="primary"
                                                autoFocus
                                                disabled={saving}
                                                startIcon={
                                                    saving ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            style={{ width: 20, height: 20 }}
                                                        />
                                                    ) : null
                                                }
                                            >
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                            </FormDataConsumer>
                        </DialogActions>
                    </div>
                )}
            />
        </Dialog>
    );
};

const OrderModal = ({
    openOrder,
    setOpenOrder,
    selectedOrder,
    availableService,
    booking,
    refreshBooking,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [openCancel, setOpenCancel] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleOpenCancel = () => {
        setOpenCancel(true);
    };

    const handleCloseCancel = () => {
        setOpenCancel(false);
    };

    const addOrder = (formData) => {
        setSaving(true);

        var selected_service = availableService.filter(
            (fitem) => fitem.id === formData.service_id
        )[0];

        var request = {
            tenant_id: authInfo.tenant.id,
            accommodation_id: formData.accommodation_id,
            service_id: formData.service_id,
            qty: formData.qty,
            price: selected_service.price,
            service_charge: selected_service.service_charge,
            total_price:
                (selected_service.price +
                    selected_service.price * selected_service.service_charge) *
                formData.qty,
            booking_id: booking.id,
            description: selected_service.name,
            order_type: "Service",
            status: "New",
        };
        dataProvider
            .create("orders", { data: request })
            .then(({ data }) => {
                setOpenOrder(false);
                refreshBooking();
                notify("Order created");
                setSaving(false);
            })
            .catch((error) => {
                notify(error);
                setSaving(false);
            });
    };

    const updateOrder = (process, formData) => {
        setSaving(true);

        var request = {};
        if (process == "update") {
            var selected_service = availableService.filter(
                (fitem) => fitem.id === formData.service_id
            )[0];

            request.accommodation_id = formData.accommodation_id;
            request.service_id = formData.service_id;
            request.qty = formData.qty;
            request.price = selected_service.price;
            request.service_charge = selected_service.service_charge;
            request.total_price =
                (selected_service.price +
                    selected_service.price * selected_service.service_charge) *
                formData.qty;
            request.booking_id = booking.id;
            request.description = selected_service.name;
            request.order_type = "Service";
            request.status = "New";
        }
        if (process == "cancel") {
            request.status = "Cancelled";
        }

        dataProvider
            .update("orders", {
                id: selectedOrder.id,
                data: { ...request },
            })
            .then(({ data }) => {
                setOpenOrder(false);
                setOpenCancel(false);
                refreshBooking();
                notify("Order updated");
                setSaving(false);
            })
            .catch((error) => {
                setOpenCancel(false);
                notify(error);
                setSaving(false);
            });
    };

    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenOrder(false)}
            aria-labelledby="customized-dialog-title"
            open={openOrder}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenOrder(false)}
            >
                {selectedOrder != null
                    ? "Order " + formatter.orderNo(selectedOrder.order_no)
                    : "Add Order"}
            </DialogTitle>
            <FormWithRedirect
                onSubmit={() => { }}
                record={selectedOrder}
                render={(formProps) => (
                    <div>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <SelectInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Accommodation"
                                        source="accommodation_id"
                                        choices={booking.accommodations.reduce(function (
                                            result,
                                            option
                                        ) {
                                            if (option.status == "Checked_In") {
                                                return result.concat({
                                                    id: option.id,
                                                    name: option.room.name,
                                                });
                                            }
                                            return result;
                                        },
                                            [])}
                                        options={{
                                            InputProps: { autoComplete: "no" },
                                        }}
                                        validate={[required()]}
                                        disabled={
                                            selectedOrder?.status == "Invoiced" ||
                                            selectedOrder?.status == "Paid"
                                        }
                                    />
                                </Grid>
                                {selectedOrder == null ||
                                    selectedOrder.order_type == "Service" ? (
                                    <Grid item xs={12} sm={6} className={classes.formGrid}>
                                        <SelectInput
                                            className={classes.formInput}
                                            fullWidth
                                            label="Service"
                                            source="service_id"
                                            choices={availableService.map((item) => {
                                                return {
                                                    id: item.id,
                                                    name: item.name,
                                                };
                                            })}
                                            optionText="name"
                                            options={{
                                                InputProps: { autoComplete: "no" },
                                            }}
                                            validate={[required()]}
                                            disabled={
                                                selectedOrder?.status == "Invoiced" ||
                                                selectedOrder?.status == "Paid"
                                            }
                                        />
                                    </Grid>
                                ) : null}
                                {selectedOrder == null ||
                                    selectedOrder.order_type == "Service" ? (
                                    <Grid item xs={12} sm={6} className={classes.formGrid}>
                                        <NumberInput
                                            className={classes.formInput}
                                            fullWidth
                                            label="Quantity"
                                            source="qty"
                                            validate={[required(), number(), minValue(1)]}
                                            disabled={
                                                selectedOrder?.status == "Invoiced" ||
                                                selectedOrder?.status == "Paid"
                                            }
                                        />
                                    </Grid>
                                ) : null}
                                {selectedOrder != null &&
                                    selectedOrder.order_type == "Bleudine" ? (
                                    <Grid item xs={6} className={classes.formGrid}>
                                        <Typography gutterBottom>Description</Typography>
                                    </Grid>
                                ) : null}
                                {selectedOrder != null &&
                                    selectedOrder.order_type == "Bleudine" ? (
                                    <Grid item xs={6} className={classes.formGrid}>
                                        <Typography gutterBottom style={{ textAlign: "right" }}>
                                            {selectedOrder.description}
                                        </Typography>
                                    </Grid>
                                ) : null}
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Price</Typography>
                                </Grid>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        var selected_service = availableService.filter(
                                            (fitem) => fitem.id === formData.service_id
                                        )[0];

                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                className={classes.formGrid}
                                                align="right"
                                            >
                                                {selectedOrder != null && formData.qty
                                                    ? formatter.formatMoney(
                                                        selectedOrder.price * formData.qty,
                                                        authInfo.tenant.locale.currency_code,
                                                        authInfo.tenant.locale.precision
                                                    )
                                                    : selected_service && formData.qty
                                                        ? formatter.formatMoney(
                                                            selected_service.price * formData.qty,
                                                            authInfo.tenant.locale.currency_code,
                                                            authInfo.tenant.locale.precision
                                                        )
                                                        : "-"}
                                            </Grid>
                                        );
                                    }}
                                </FormDataConsumer>
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Tax</Typography>
                                </Grid>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        var selected_service = availableService.filter(
                                            (fitem) => fitem.id === formData.service_id
                                        )[0];

                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                className={classes.formGrid}
                                                align="right"
                                            >
                                                {selectedOrder != null && formData.qty
                                                    ? formatter.formatMoney(
                                                        selectedOrder.service_charge *
                                                        selectedOrder.price *
                                                        formData.qty,
                                                        authInfo.tenant.locale.currency_code,
                                                        authInfo.tenant.locale.precision
                                                    )
                                                    : selected_service && formData.qty
                                                        ? formatter.formatMoney(
                                                            selected_service.service_charge *
                                                            selected_service.price *
                                                            formData.qty,
                                                            authInfo.tenant.locale.currency_code,
                                                            authInfo.tenant.locale.precision
                                                        )
                                                        : "-"}
                                            </Grid>
                                        );
                                    }}
                                </FormDataConsumer>
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Total Price</Typography>
                                </Grid>
                                <FormDataConsumer>
                                    {({ formData, ...rest }) => {
                                        var selected_service = availableService.filter(
                                            (fitem) => fitem.id === formData.service_id
                                        )[0];

                                        return (
                                            <Grid
                                                item
                                                xs={6}
                                                className={classes.formGrid}
                                                align="right"
                                            >
                                                {selectedOrder != null && formData.qty
                                                    ? formatter.formatMoney(
                                                        (selectedOrder.price +
                                                            selectedOrder.service_charge *
                                                            selectedOrder.price) *
                                                        formData.qty,
                                                        authInfo.tenant.locale.currency_code,
                                                        authInfo.tenant.locale.precision
                                                    )
                                                    : selected_service && formData.qty
                                                        ? formatter.formatMoney(
                                                            (selected_service.price +
                                                                selected_service.service_charge *
                                                                selected_service.price) *
                                                            formData.qty,
                                                            authInfo.tenant.locale.currency_code,
                                                            authInfo.tenant.locale.precision
                                                        )
                                                        : "-"}
                                            </Grid>
                                        );
                                    }}
                                </FormDataConsumer>
                                {selectedOrder ? (
                                    <Grid item xs={6} className={classes.formGrid}>
                                        <Typography gutterBottom>Status</Typography>
                                    </Grid>
                                ) : null}
                                {selectedOrder ? (
                                    <Grid
                                        item
                                        xs={6}
                                        className={classes.formGrid}
                                        style={{ textAlign: "right" }}
                                    >
                                        <Chip
                                            label={selectedOrder?.e_order_status.comment}
                                            style={{
                                                color: "white",
                                                background: orderStatus.getColor(selectedOrder?.status),
                                            }}
                                        />
                                    </Grid>
                                ) : null}
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedOrder == null ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            className={classes.formInput}
                                            onSave={() => addOrder(formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedOrder?.status == "New" ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            label="Update"
                                            className={classes.formInput}
                                            onSave={() => updateOrder("update", formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedOrder?.status == "New" ? (
                                        <SaveButton
                                            {...formProps}
                                            style={{ background: "red" }}
                                            variant="contained"
                                            color="primary"
                                            label="Cancel Order"
                                            icon={<ClearIcon />}
                                            className={classes.formInput}
                                            onSave={handleOpenCancel}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            <FormDataConsumer>
                                {({ formData, ...rest }) => (
                                    <Dialog
                                        open={openCancel}
                                        onClose={handleCloseCancel}
                                        aria-labelledby="alert-dialog-title"
                                        aria-describedby="alert-dialog-description"
                                    >
                                        <DialogTitle id="alert-dialog-title">
                                            {"Confirm Cancel?"}
                                        </DialogTitle>
                                        <DialogContent>
                                            <DialogContentText id="alert-dialog-description">
                                                Please confirm to cancel
                                            </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                            <Button
                                                onClick={() => handleCloseCancel()}
                                                color="primary"
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={() => updateOrder("cancel", formData)}
                                                color="primary"
                                                autoFocus
                                                disabled={saving}
                                                startIcon={
                                                    saving ? (
                                                        <CircularProgress
                                                            color="inherit"
                                                            style={{ width: 20, height: 20 }}
                                                        />
                                                    ) : null
                                                }
                                            >
                                                Confirm
                                            </Button>
                                        </DialogActions>
                                    </Dialog>
                                )}
                            </FormDataConsumer>
                        </DialogActions>
                    </div>
                )}
            />
        </Dialog>
    );
};

const InvoiceModal = ({
    openInvoice,
    setOpenInvoice,
    selectedInvoice,
    availableService,
    booking,
    selectedOrderIds,
    setSelectedOrderIds,
    setOpenPayment,
    setOpenInvoiceDetail,
    refreshBooking,
    selectedInvoiceSubtotal,
    setSelectedInvoiceSubtotal,
    selectedInvoiceTax,
    setSelectedInvoiceTax,
    selectedInvoiceTotal,
    setSelectedInvoiceTotal,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [openCancel, setOpenCancel] = React.useState(false);
    const [saving, setSaving] = React.useState(false);

    const handleOpenCancel = () => {
        setOpenCancel(true);
    };

    const handleCloseCancel = () => {
        setOpenCancel(false);
    };

    const addInvoice = (formData) => {
        setSaving(true);

        let isValid = true;
        if (selectedOrderIds.length <= 0) {
            notify("Please select order to create invoice");
            isValid = false;
        }

        if (isValid) {
            var selectedOrders = booking.orders
                .filter((item) => {
                    return selectedOrderIds.includes(item.id);
                })
                .map((item) => {
                    return {
                        accommodation_id: item.accommodation_id,
                        booking_id: item.booking_id,
                        description: item.description,
                        order_no: item.order_no,
                        order_type: item.order_type,
                        price: item.price,
                        qty: item.qty,
                        service_charge: item.service_charge,
                        order_id: item.order_id,
                        total_price: item.total_price,
                        tenant_id: item.tenant_id,
                        order_id: item.id,
                    };
                });
            var request = {
                tenant_id: authInfo.tenant.id,
                booking_id: booking.id,
                subtotal: selectedInvoiceSubtotal,
                tax: selectedInvoiceTax,
                total: selectedInvoiceTotal,
                status: "New",
                invoice_items: { data: selectedOrders },
            };
            dataProvider
                .create("invoices", { data: request })
                .then(({ data }) => {
                    selectedOrderIds.forEach((sid) => {
                        dataProvider.update("orders", {
                            id: sid,
                            data: { status: "Invoiced", invoice_id: data.id },
                        });
                    });
                    setOpenInvoice(false);
                    refreshBooking();
                    notify("Invoice created");
                    setSaving(false);
                })
                .catch((error) => {
                    notify(error);
                    setSaving(false);
                });
        } else {
            setSaving(false);
        }
    };

    const updateInvoice = (process, formData) => {
        setSaving(true);

        var request = {};
        if (process == "cancel") {
            request.status = "Cancelled";
        }

        dataProvider
            .update("invoices", {
                id: selectedInvoice.id,
                data: { ...request },
            })
            .then(({ data }) => {
                if (process == "cancel") {
                    selectedInvoice.ordersIds.forEach((sid) => {
                        dataProvider
                            .update("orders", {
                                id: sid,
                                data: { status: "New", invoice_id: null },
                            })
                            .then(({ data }) => {
                                refreshBooking();
                            });
                    });
                }

                setOpenInvoice(false);
                setOpenCancel(false);
                refreshBooking();
                notify("Invoice updated");
                setSaving(false);
            })
            .catch((error) => {
                setOpenCancel(false);
                notify(error);
                setSaving(false);
            });
    };

    const orderColumns = [
        { field: "order_no", headerName: "Order No", width: 150 },
        { field: "room", headerName: "Room", width: 120 },
        {
            field: "description",
            headerName: "Description",
            width: 500,
            sortable: false,
        },
        {
            field: "price",
            headerName: "Price",
            width: 150,
            sortable: false,
        },
        {
            field: "service_charge",
            headerName: "Service Charge",
            width: 180,
            sortable: false,
        },
        {
            field: "qty",
            headerName: "Quantity",
            width: 120,
            sortable: false,
        },
        {
            field: "total_price",
            headerName: "Total Price",
            width: 150,
            sortable: false,
        },
        {
            field: "created_at",
            headerName: "Order Date",
            width: 200,
            sortable: false,
        },
    ];

    let orderRows = booking?.orders.reduce(function (result, option) {
        if (selectedInvoice == null && option.status == "New") {
            return result.concat({
                id: option.id,
                order_no: formatter.orderNo(option.order_no),
                room: option.accommodation.room?.name,
                description: option.description,
                price: formatter.formatMoney(
                    option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                service_charge: formatter.formatMoney(
                    option.service_charge * option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                price_raw: option.price,
                service_charge_raw: option.service_charge * option.price,
                qty: option.qty,
                total_price: formatter.formatMoney(
                    option.total_price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                total_price_raw: option.total_price,
                created_at: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
            });
        }
        return result;
    }, []);

    if (selectedInvoice != null) {
        orderRows = selectedInvoice.invoice_items.map((option) => {
            return {
                id: option.order_id,
                order_no: formatter.orderNo(option.order_no),
                room: option.accommodation?.room?.name,
                description: option.description,
                price: formatter.formatMoney(
                    option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                service_charge: formatter.formatMoney(
                    option.service_charge * option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                qty: option.qty,
                total_price: formatter.formatMoney(
                    option.total_price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                created_at: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
            };
        });
    }

    const paymentColumns = [
        { field: "payment_no", headerName: "Payment No", width: 180 },
        { field: "payment_type", headerName: "Payment Type", width: 180 },
        {
            field: "amount",
            headerName: "Amount",
            width: 200,
            sortable: false,
        },
        {
            field: "received_amount",
            headerName: "Received Amount",
            width: 200,
            sortable: false,
        },
        {
            field: "remarks",
            headerName: "Remarks",
            width: 200,
            sortable: false,
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            sortable: false,
        },
        {
            field: "payment_date",
            headerName: "Payment Date",
            width: 200,
            sortable: false,
        },
    ];

    const paymentRows = selectedInvoice?.invoice_payments.map(function (option) {
        return {
            id: option.id,
            payment_no: formatter.paymentNo(option.payment_no),
            payment_type: option.e_payment_type.comment,
            amount: formatter.formatMoney(
                option.amount,
                authInfo.tenant.locale.currency_code,
                authInfo.tenant.locale.precision
            ),
            received_amount: formatter.formatMoney(
                option.received_amount,
                option.received_currency,
                null
            ),
            remarks: option.remarks,
            status: option.e_payment_status.comment,
            payment_date: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
        };
    });

    const selectOrder = (ids) => {
        let price = 0;
        let tax = 0;
        let total_price = 0;
        orderRows.forEach((obj) => {
            if (ids.includes(obj.id)) {
                price += obj.price_raw * obj.qty;
                tax += obj.service_charge_raw * obj.qty;
                total_price += obj.total_price_raw;
            }
        });
        setSelectedOrderIds(ids);
        setSelectedInvoiceSubtotal(price);
        setSelectedInvoiceTax(tax);
        setSelectedInvoiceTotal(total_price);
    };

    return (
        <FormWithRedirect
            onSubmit={() => { }}
            record={selectedInvoice}
            render={(formProps) => (
                <Dialog
                    //maxWidth="lg"
                    fullScreen
                    fullWidth={true}
                    onClose={() => setOpenInvoice(false)}
                    aria-labelledby="customized-dialog-title"
                    open={openInvoice}
                >
                    <DialogTitle
                        id="customized-dialog-title"
                        onClose={() => setOpenInvoice(false)}
                    >
                        {selectedInvoice != null
                            ? "Edit Invoice " +
                            formatter.invoiceNo(selectedInvoice.invoice_no)
                            : "Add Invoice"}
                    </DialogTitle>
                    <DialogContent dividers>
                        <Grid container spacing={2}>
                            <Grid item xs={12} className={classes.formGrid}>
                                <Typography
                                    variant="h6"
                                    className={classes.textBold}
                                    gutterBottom
                                >
                                    Orders
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                className={classes.formGrid}
                                style={{ marginBottom: 10 }}
                            >
                                <div style={{ height: 400, width: "100%" }}>
                                    <DataGrid
                                        rows={orderRows}
                                        columns={orderColumns}
                                        checkboxSelection={selectedInvoice == null}
                                        disableSelectionOnClick
                                        rowsPerPageOptions={[]}
                                        selectionModel={selectedOrderIds}
                                        onSelectionModelChange={(ids) => {
                                            selectOrder(ids);
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        {selectedInvoice != null ? <br /> : null}
                        {selectedInvoice != null ? <br /> : null}
                        {selectedInvoice != null ? (
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <Typography
                                        variant="h6"
                                        className={classes.textBold}
                                        gutterBottom
                                    >
                                        Payments
                                        {selectedInvoice.status != "Paid" &&
                                            selectedInvoice.status != "Cancelled" ? (
                                            <Button
                                                style={{ float: "right", color: "#4f3cc9" }}
                                                variant="outline"
                                                color="primary"
                                                startIcon={<AddIcon />}
                                                onClick={() => {
                                                    setOpenPayment(true);
                                                }}
                                            >
                                                Add
                                            </Button>
                                        ) : null}
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    className={classes.formGrid}
                                    style={{ marginBottom: 10 }}
                                >
                                    <div style={{ height: 400, width: "100%" }}>
                                        <DataGrid
                                            rows={paymentRows}
                                            columns={paymentColumns}
                                            checkboxSelection={selectedInvoice == null}
                                            disableSelectionOnClick
                                            rowsPerPageOptions={[]}
                                            selectionModel={selectedOrderIds}
                                            onSelectionModelChange={(ids) => {
                                                selectOrder(ids);
                                            }}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        ) : null}

                        <br />
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={6} className={classes.formGrid}>
                                <Typography gutterBottom>Subtotal</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.formGrid} align="right">
                                {selectedInvoice != null
                                    ? formatter.formatMoney(
                                        selectedInvoice.subtotal,
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )
                                    : selectedInvoiceSubtotal
                                        ? formatter.formatMoney(
                                            selectedInvoiceSubtotal,
                                            authInfo.tenant.locale.currency_code,
                                            authInfo.tenant.locale.precision
                                        )
                                        : "-"}
                            </Grid>
                            <Grid item xs={6} className={classes.formGrid}>
                                <Typography gutterBottom>Tax</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.formGrid} align="right">
                                {selectedInvoice != null
                                    ? formatter.formatMoney(
                                        selectedInvoice.tax,
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )
                                    : selectedInvoiceTax
                                        ? formatter.formatMoney(
                                            selectedInvoiceTax,
                                            authInfo.tenant.locale.currency_code,
                                            authInfo.tenant.locale.precision
                                        )
                                        : "-"}
                            </Grid>
                            <Grid item xs={6} className={classes.formGrid}>
                                <Typography gutterBottom>Amount</Typography>
                            </Grid>
                            <Grid item xs={6} className={classes.formGrid} align="right">
                                {selectedInvoice != null
                                    ? formatter.formatMoney(
                                        selectedInvoice.total,
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )
                                    : selectedInvoiceTotal
                                        ? formatter.formatMoney(
                                            selectedInvoiceTotal,
                                            authInfo.tenant.locale.currency_code,
                                            authInfo.tenant.locale.precision
                                        )
                                        : "-"}
                            </Grid>
                            {selectedInvoice != null ? (
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Paid</Typography>
                                </Grid>
                            ) : null}
                            {selectedInvoice != null ? (
                                <Grid item xs={6} className={classes.formGrid} align="right">
                                    -(
                                    {formatter.formatMoney(
                                        selectedInvoice.invoice_payments
                                            .filter((record) => record.status == "Success")
                                            .reduce((sum, record) => sum + record.amount, 0),
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )}
                                    )
                                </Grid>
                            ) : null}
                            {selectedInvoice != null ? (
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Due Amount</Typography>
                                </Grid>
                            ) : null}
                            {selectedInvoice != null ? (
                                <Grid item xs={6} className={classes.formGrid} align="right">
                                    {selectedInvoice != null
                                        ? formatter.formatMoney(
                                            selectedInvoice.total -
                                            selectedInvoice.invoice_payments
                                                .filter((record) => record.status == "Success")
                                                .reduce((sum, record) => sum + record.amount, 0),
                                            authInfo.tenant.locale.currency_code,
                                            authInfo.tenant.locale.precision
                                        )
                                        : selectedInvoiceTotal
                                            ? formatter.formatMoney(
                                                selectedInvoiceTotal,
                                                authInfo.tenant.locale.currency_code,
                                                authInfo.tenant.locale.precision
                                            )
                                            : "-"}
                                </Grid>
                            ) : null}
                            {selectedInvoice ? (
                                <Grid item xs={6} className={classes.formGrid}>
                                    <Typography gutterBottom>Status</Typography>
                                </Grid>
                            ) : null}
                            {selectedInvoice ? (
                                <Grid
                                    item
                                    xs={6}
                                    className={classes.formGrid}
                                    style={{ textAlign: "right" }}
                                >
                                    <Chip
                                        label={selectedInvoice?.e_invoice_status.comment}
                                        style={{
                                            color: "white",
                                            background: invoiceStatus.getColor(
                                                selectedInvoice?.status
                                            ),
                                        }}
                                    />
                                </Grid>
                            ) : null}
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                selectedOrderIds.length > 0 || selectedInvoice != null ? (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<PageviewIcon />}
                                        className={classes.formInput}
                                        onClick={() => setOpenInvoiceDetail(true)}
                                    >
                                        View
                                    </Button>
                                ) : null
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                selectedInvoice == null ? (
                                    <SaveButton
                                        {...formProps}
                                        variant="contained"
                                        color="primary"
                                        className={classes.formInput}
                                        onSave={() => addInvoice(formData)}
                                        saving={saving}
                                        disabled={saving}
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) =>
                                selectedInvoice?.status == "New" ? (
                                    <SaveButton
                                        {...formProps}
                                        style={{ background: "red" }}
                                        variant="contained"
                                        color="primary"
                                        label="Cancel Invoice"
                                        icon={<ClearIcon />}
                                        className={classes.formInput}
                                        onSave={handleOpenCancel}
                                    />
                                ) : null
                            }
                        </FormDataConsumer>
                        <FormDataConsumer>
                            {({ formData, ...rest }) => (
                                <Dialog
                                    open={openCancel}
                                    onClose={handleCloseCancel}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                >
                                    <DialogTitle id="alert-dialog-title">
                                        {"Confirm Cancel?"}
                                    </DialogTitle>
                                    <DialogContent>
                                        <DialogContentText id="alert-dialog-description">
                                            Please confirm to cancel
                                        </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={() => handleCloseCancel()} color="primary">
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => updateInvoice("cancel", formData)}
                                            color="primary"
                                            autoFocus
                                            disabled={saving}
                                            startIcon={
                                                saving ? (
                                                    <CircularProgress
                                                        color="inherit"
                                                        style={{ width: 20, height: 20 }}
                                                    />
                                                ) : null
                                            }
                                        >
                                            Confirm
                                        </Button>
                                    </DialogActions>
                                </Dialog>
                            )}
                        </FormDataConsumer>
                    </DialogActions>
                </Dialog>
            )}
        />
    );
};

const PaymentModal = ({
    openPayment,
    setOpenPayment,
    selectedPayment,
    availableService,
    booking,
    selectedInvoice,
    refreshBooking,
    refreshSelectedInvoice,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);
    const [saving, setSaving] = React.useState(false);

    const updateInvoice = (formData) => {
        let paidAmount =
            selectedInvoice.invoice_payments
                .filter((record) => record.status == "Success")
                .reduce((sum, record) => sum + record.amount, 0) + formData.amount;

        dataProvider
            .update("invoices", {
                id: selectedInvoice.id,
                data: {
                    status:
                        paidAmount == selectedInvoice.total ? "Paid" : "Partially_Paid",
                },
            })
            .then(({ data }) => {
                if (paidAmount == selectedInvoice.total) {
                    booking.orders
                        .filter((record) => record.invoice_id == selectedInvoice.id)
                        .forEach((record) => {
                            dataProvider.update("orders", {
                                id: record.id,
                                data: {
                                    status: "Paid",
                                },
                            });
                        });
                }

                setOpenPayment(false);
                refreshSelectedInvoice(selectedInvoice.id);
                refreshBooking();
                notify("Payment created");
                setSaving(false);
            })
            .catch((error) => {
                notify(error);
                setSaving(false);
            });
    };

    const removePayment = (id) => {
        dataProvider
            .delete("invoice_payments", { id: id })
            .then(({ data }) => { })
            .catch((error) => {
                notify(error);
            });
    };

    const addPayment = (formData) => {
        let midtransCurrency = "IDR";
        let tenantCurrencyCode = authInfo.tenant.locale.currency_code;
        let midtransAmount = formData.amount;
        fetch(
            `https://api.exchangerate.host/convert?from=${tenantCurrencyCode}&to=${midtransCurrency}&amount=${formData.amount}`
        )
            .then((res) => res.json())
            .then(
                (result) => {
                    midtransAmount = result.result;
                    setSaving(true);
                    var request = {
                        tenant_id: authInfo.tenant.id,
                        invoice_id: selectedInvoice.id,
                        payment_type: formData.payment_type,
                        deposit_id: formData.payment_type == "Deposit" ? formData.deposit_id : null,
                        amount: formData.amount,
                        received_currency: formData.payment_type == "Credit_Card" ? midtransCurrency : tenantCurrencyCode,
                        received_amount: formData.payment_type == "Credit_Card" ? midtransAmount : formData.amount,
                        remarks: formData.remarks,
                        status: "Success",
                    };
                    dataProvider
                        .create("invoice_payments", { data: request })
                        .then(({ data }) => {
                            if (formData.payment_type == "Credit_Card") {
                                var card = {
                                    card_number: formData.card_number,
                                    card_cvv: formData.card_cvv,
                                    card_exp_month: formData.card_exp_month,
                                    card_exp_year: formData.card_exp_year,
                                };

                                var options = {
                                    onSuccess: function (response) {
                                        //call payment service
                                        var paymentrequest = {
                                            tokenId: response.token_id,
                                            amount: midtransAmount,
                                            orderId: formatter.paymentNo(data.payment_no),
                                            referenceType: paymentReferenceType.PMSInvoicePayment,
                                            referenceId: data.id,
                                            firstName: booking.guest.first_name,
                                            lastName: booking.guest.last_name,
                                            email: booking.guest.email,
                                            phoneNumber: booking.guest.phone_no,
                                            isPreauthorize: false,
                                        };
                                        paymentApi.midtransChargeWithToken(paymentrequest).then(
                                            (paymentData) => {
                                                if (paymentData.statusCode == "200") {
                                                    dataProvider
                                                        .update("invoice_payments", {
                                                            id: data.id,
                                                            data: {
                                                                payment_transaction_id: paymentData.transactionId,
                                                            },
                                                        })
                                                        .then(({ uData }) => {
                                                            updateInvoice(formData);
                                                        })
                                                        .catch((error) => {
                                                            notify(error);
                                                            setSaving(false);
                                                        });
                                                } else {
                                                    notify("Fail to charge payment");
                                                    removePayment(data.id);
                                                    setSaving(false);
                                                }
                                            },
                                            (error) => {
                                                notify("Fail to charge payment");
                                                removePayment(data.id);
                                                setSaving(false);
                                            }
                                        );
                                    },
                                    onFailure: function (response) {
                                        notify("Fail to authenticate payment");
                                        removePayment(data.id);
                                        setSaving(false);
                                    },
                                };
                                window.MidtransNew3ds.getCardToken(card, options);
                            } else if (formData.payment_type == "Deposit") {
                                let selectedDeposit = booking.deposits.filter(
                                    (item) => item.id == formData.deposit_id
                                )[0];
                                if (selectedDeposit.status == "Preauthorized") {
                                    //call payment service
                                    var paymentrequest = {
                                        transactionId: selectedDeposit.payment_transaction_id,
                                        amount: selectedDeposit.received_amount,
                                    };
                                    paymentApi.midtransCapture(paymentrequest).then(
                                        (paymentData) => {
                                            dataProvider
                                                .update("deposits", {
                                                    id: selectedDeposit.id,
                                                    data: { status: "Paid" },
                                                })
                                                .then(({ uData }) => {
                                                    updateInvoice(formData);
                                                })
                                                .catch((error) => {
                                                    notify(error);
                                                    setSaving(false);
                                                });
                                        },
                                        (error) => {
                                            notify("Fail to charge payment");
                                            removePayment(data.id);
                                            setSaving(false);
                                        }
                                    );
                                } else {
                                    updateInvoice(formData);
                                }
                            } else {
                                updateInvoice(formData);
                            }
                        })
                        .catch((error) => {
                            notify(error);
                            setSaving(false);
                        });
                },
                (error) => { }
            );
    };
    return (
        <Dialog
            maxWidth="sm"
            fullWidth={true}
            onClose={() => setOpenPayment(false)}
            aria-labelledby="customized-dialog-title"
            open={openPayment}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenPayment(false)}
            >
                {selectedPayment != null
                    ? "Edit Payment " + formatter.paymentNo(selectedPayment.payment_no)
                    : "Add Payment"}
            </DialogTitle>
            <FormWithRedirect
                onSubmit={() => { }}
                record={selectedPayment}
                render={(formProps) => (
                    <div>
                        <DialogContent dividers>
                            <Grid container spacing={2}>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <ReferenceInput
                                        className={classes.formInput}
                                        fullWidth
                                        label="Payment Type"
                                        source="payment_type"
                                        reference="e_payment_types"
                                        validate={[required()]}
                                    >
                                        <SelectInput optionText="comment" />
                                    </ReferenceInput>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.payment_type == "Deposit") {
                                                let deposits = booking.deposits.filter((item) => {
                                                    return (
                                                        item.status != "Cancelled" &&
                                                        item.amount >
                                                        item.invoice_payments.reduce(
                                                            (total, obj) => obj.amount + total,
                                                            0
                                                        ) +
                                                        item.refunds
                                                            .filter((rfn) => {
                                                                return rfn.status != "Cancelled";
                                                            })
                                                            .reduce((total, obj) => obj.amount + total, 0)
                                                    );
                                                });
                                                return (
                                                    <SelectInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        label="Deposit"
                                                        source="deposit_id"
                                                        choices={deposits.map((item) => {
                                                            return {
                                                                id: item.id,
                                                                name:
                                                                    formatter.depositNo(item.deposit_no) +
                                                                    " - " +
                                                                    formatter.formatMoney(
                                                                        item.amount -
                                                                        item.invoice_payments.reduce(
                                                                            (total, obj) => obj.amount + total,
                                                                            0
                                                                        ),
                                                                        authInfo.tenant.locale.currency_code,
                                                                        authInfo.tenant.locale.precision
                                                                    ),
                                                            };
                                                        })}
                                                        optionText="name"
                                                        options={{
                                                            InputProps: { autoComplete: "no" },
                                                        }}
                                                        validate={[required()]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.payment_type == "Credit_Card") {
                                                return (
                                                    <TextInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        type="number"
                                                        source="card_number"
                                                        label="Card Number"
                                                        validate={[required()]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.payment_type == "Credit_Card") {
                                                return (
                                                    <TextInput
                                                        type="number"
                                                        className={classes.formInput}
                                                        fullWidth
                                                        source="card_cvv"
                                                        label="CVV"
                                                        validate={[required()]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.payment_type == "Credit_Card") {
                                                return (
                                                    <SelectInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        source="card_exp_month"
                                                        label="Expiry Month"
                                                        validate={[required()]}
                                                        choices={[
                                                            { id: "01", name: "January" },
                                                            { id: "02", name: "February" },
                                                            { id: "03", name: "March" },
                                                            { id: "04", name: "April" },
                                                            { id: "05", name: "May" },
                                                            { id: "06", name: "June" },
                                                            { id: "07", name: "July" },
                                                            { id: "08", name: "August" },
                                                            { id: "09", name: "September" },
                                                            { id: "10", name: "October" },
                                                            { id: "11", name: "November" },
                                                            { id: "12", name: "December" },
                                                        ]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            if (formData.payment_type == "Credit_Card") {
                                                return (
                                                    <SelectInput
                                                        className={classes.formInput}
                                                        fullWidth
                                                        source="card_exp_year"
                                                        label="Expiry Year"
                                                        validate={[required()]}
                                                        choices={[
                                                            { id: "2021", name: "2021" },
                                                            { id: "2022", name: "2022" },
                                                            { id: "2023", name: "2023" },
                                                            { id: "2024", name: "2024" },
                                                            { id: "2025", name: "2025" },
                                                        ]}
                                                    />
                                                );
                                            }
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => {
                                            let maxVal =
                                                selectedInvoice.total -
                                                selectedInvoice.invoice_payments
                                                    .filter((record) => record.status == "Success")
                                                    .reduce((sum, record) => sum + record.amount, 0);
                                            if (
                                                formData.payment_type == "Deposit" &&
                                                formData.deposit_id != null
                                            ) {
                                                let deposits = booking.deposits.filter((item) => {
                                                    return (
                                                        item.status != "Cancelled" &&
                                                        item.amount >
                                                        item.invoice_payments.reduce(
                                                            (total, obj) => obj.amount + total,
                                                            0
                                                        )
                                                    );
                                                });
                                                let deposit = deposits.filter((item) => {
                                                    return item.id == formData.deposit_id;
                                                })[0];
                                                if (deposit != null) {
                                                    let depositLimit =
                                                        deposit.amount -
                                                        deposit.invoice_payments.reduce(
                                                            (total, obj) => obj.amount + total,
                                                            0
                                                        ) -
                                                        deposit.refunds
                                                            .filter((item) => {
                                                                return item.status != "Cancelled";
                                                            })
                                                            .reduce((total, obj) => obj.amount + total, 0);
                                                    maxVal = maxVal > depositLimit ? depositLimit : maxVal;
                                                }
                                            }
                                            return (
                                                <NumberInput
                                                    className={classes.formInput}
                                                    fullWidth
                                                    label="Amount"
                                                    source="amount"
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                {authInfo.tenant.locale.currency_code}
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    validate={[
                                                        required(),
                                                        number(),
                                                        minValue(1),
                                                        maxValue(maxVal),
                                                    ]}
                                                />
                                            );
                                        }}
                                    </FormDataConsumer>
                                </Grid>
                                <Grid item xs={12} className={classes.formGrid}>
                                    <TextInput
                                        className={classes.formInput}
                                        source="remarks"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <FormDataConsumer>
                                {({ formData, ...rest }) =>
                                    selectedPayment == null ? (
                                        <SaveButton
                                            {...formProps}
                                            variant="contained"
                                            color="primary"
                                            className={classes.formInput}
                                            onSave={() => addPayment(formData)}
                                            saving={saving}
                                            disabled={saving}
                                        />
                                    ) : null
                                }
                            </FormDataConsumer>
                            {/* <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedPayment?.status == "New" ? (
                    <SaveButton
                      {...formProps}
                      variant="contained"
                      color="primary"
                      label="Update"
                      className={classes.formInput}
                      onSave={() => updatePayment("update", formData)}
                    />
                  ) : null
                }
              </FormDataConsumer>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  selectedPayment?.status == "New" ? (
                    <SaveButton
                      {...formProps}
                      style={{ background: "red" }}
                      variant="contained"
                      color="primary"
                      label="Cancel"
                      icon={<ClearIcon />}
                      className={classes.formInput}
                      onSave={() => updatePayment("cancel", formData)}
                    />
                  ) : null
                }
              </FormDataConsumer> */}
                        </DialogActions>
                    </div>
                )}
            />
        </Dialog>
    );
};

const InvoiceDetailModal = ({
    openInvoiceDetail,
    setOpenInvoiceDetail,
    selectedInvoice,
    selectedOrderIds,
    booking,
    selectedInvoiceSubtotal,
    setSelectedInvoiceSubtotal,
    selectedInvoiceTax,
    setSelectedInvoiceTax,
    selectedInvoiceTotal,
    setSelectedInvoiceTotal,
}) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    let componentRef = useRef();

    let orderRows = booking?.orders.reduce(function (result, option) {
        if (selectedInvoice == null && selectedOrderIds.includes(option.id)) {
            return result.concat({
                id: option.id,
                order_no: formatter.orderNo(option.order_no),
                room: option.accommodation.room?.name,
                description: option.description,
                price: formatter.formatMoney(
                    option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                service_charge: formatter.formatMoney(
                    option.service_charge * option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                qty: option.qty,
                total_price: formatter.formatMoney(
                    option.total_price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                created_at: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
            });
        }
        return result;
    }, []);

    if (selectedInvoice != null) {
        orderRows = selectedInvoice.invoice_items.map((option) => {
            return {
                id: option.order_id,
                order_no: formatter.orderNo(option.order_no),
                room: option.accommodation?.room?.name,
                description: option.description,
                price: formatter.formatMoney(
                    option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                service_charge: formatter.formatMoney(
                    option.service_charge * option.price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                qty: option.qty,
                total_price: formatter.formatMoney(
                    option.total_price,
                    authInfo.tenant.locale.currency_code,
                    authInfo.tenant.locale.precision
                ),
                created_at: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
            };
        });
    }

    const paymentRows = selectedInvoice?.invoice_payments.map(function (option) {
        return {
            id: option.id,
            payment_no: formatter.paymentNo(option.payment_no),
            payment_type: option.e_payment_type.comment,
            amount: formatter.formatMoney(
                option.amount,
                authInfo.tenant.locale.currency_code,
                authInfo.tenant.locale.precision
            ),
            remarks: option.remarks,
            status: option.e_payment_status.comment,
            payment_date: moment(option.created_at).format("DD MMM YYYY hh:mm:ss A"),
        };
    });

    return (
        <Dialog
            maxWidth="md"
            fullWidth={true}
            onClose={() => setOpenInvoiceDetail(false)}
            aria-labelledby="customized-dialog-title"
            open={openInvoiceDetail}
        >
            <DialogTitle
                id="customized-dialog-title"
                onClose={() => setOpenInvoiceDetail(false)}
            >
                Invoice Detail
            </DialogTitle>
            <DialogContent dividers ref={(el) => (componentRef = el)}>
                <Grid container>
                    <Grid item xs={7} className={classes.formGrid}>
                        <Grid container>
                            <Grid item xs={12} className={classes.formGrid}>
                                <h1 style={{ marginBottom: 6 }}>{authInfo.tenant.name}</h1>
                            </Grid>
                            <Grid item xs={12} className={classes.formGrid}>
                                {authInfo.tenant.address_1}
                            </Grid>
                            <Grid item xs={12} className={classes.formGrid}>
                                {authInfo.tenant.address_2}
                            </Grid>
                            <Grid item xs={12} className={classes.formGrid}>
                                {authInfo.tenant.zip_code} {authInfo.tenant.region}
                            </Grid>
                            <Grid item xs={12} className={classes.formGrid}>
                                {authInfo.tenant.e_country.comment}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={5} className={classes.formGrid}>
                        <Grid container>
                            <Grid item xs={12} className={classes.formGrid}>
                                <h1 style={{ marginBottom: 6 }}>Invoice</h1>
                            </Grid>
                            <Grid item xs={12} className={classes.formGrid}>
                                Invoice No :{" "}
                                {selectedInvoice
                                    ? formatter.invoiceNo(selectedInvoice?.invoice_no)
                                    : "-"}
                            </Grid>
                            <Grid item xs={12} className={classes.formGrid}>
                                Invoice Date :{" "}
                                {selectedInvoice
                                    ? moment(selectedInvoice?.created_at).format(
                                        "DD MMM YYYY hh:mm:ss A"
                                    )
                                    : "-"}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xs={12} className={classes.formGrid}>
                        <h4 style={{ marginBottom: 6 }}>Bill To</h4>
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        {booking?.guest.first_name} {booking?.guest.last_name}
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        {booking?.guest.address_1}
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        {booking?.guest.address_2}
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        {booking?.guest.zip_code} {booking?.guest.region}
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        {booking?.guest.e_country?.comment}
                    </Grid>
                </Grid>
                <br />
                <Grid container>
                    <Grid item xs={12} className={classes.formGrid}>
                        <h4 style={{ marginBottom: 6 }}>Orders</h4>
                    </Grid>
                    <Grid item xs={12} className={classes.formGrid}>
                        <TableContainer component={Paper} style={{ fontSize: 12 }}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ fontSize: 12 }}>Order No</TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Room
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Description
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Price
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Service Charge
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Quantity
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Total Price
                                        </TableCell>
                                        <TableCell align="left" style={{ fontSize: 12 }}>
                                            Order Date
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {orderRows?.map((row) => (
                                        <TableRow key={row.name}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                                style={{ fontSize: 12 }}
                                            >
                                                {row.order_no}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.room}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.description}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.price}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.service_charge}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.qty}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.total_price}
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                {row.created_at}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                {paymentRows?.length > 0 ? (
                    <Grid container>
                        <Grid item xs={12} className={classes.formGrid}>
                            <h4 style={{ marginBottom: 6 }}>Payments</h4>
                        </Grid>
                        <Grid item xs={12} className={classes.formGrid}>
                            <TableContainer component={Paper}>
                                <Table className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ fontSize: 12 }}>Payment No</TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                Payment Type
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                Amount
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                Remarks
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                Status
                                            </TableCell>
                                            <TableCell align="left" style={{ fontSize: 12 }}>
                                                Payment Date
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymentRows?.map((row) => (
                                            <TableRow key={row.name} style={{ fontSize: 12 }}>
                                                <TableCell component="th" scope="row">
                                                    {row.payment_no}
                                                </TableCell>
                                                <TableCell align="left" style={{ fontSize: 12 }}>
                                                    {row.payment_type}
                                                </TableCell>
                                                <TableCell align="left" style={{ fontSize: 12 }}>
                                                    {row.amount}
                                                </TableCell>
                                                <TableCell align="left" style={{ fontSize: 12 }}>
                                                    {row.remarks}
                                                </TableCell>
                                                <TableCell align="left" style={{ fontSize: 12 }}>
                                                    {row.status}
                                                </TableCell>
                                                <TableCell align="left" style={{ fontSize: 12 }}>
                                                    {row.payment_date}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                ) : null}
                <Grid container spacing={2} style={{ marginTop: 20 }}>
                    <Grid item xs={6}>
                        <h4 style={{ margin: 0 }}>Subtotal</h4>
                    </Grid>
                    <Grid item xs={6} align="right">
                        {selectedInvoice != null
                            ? formatter.formatMoney(
                                selectedInvoice.subtotal,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )
                            : selectedInvoiceSubtotal
                                ? formatter.formatMoney(
                                    selectedInvoiceSubtotal,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )
                                : "-"}
                    </Grid>
                    <Grid item xs={6}>
                        <h4 style={{ margin: 0 }}>Tax</h4>
                    </Grid>
                    <Grid item xs={6} align="right">
                        {selectedInvoice != null
                            ? formatter.formatMoney(
                                selectedInvoice.tax,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )
                            : selectedInvoiceTax
                                ? formatter.formatMoney(
                                    selectedInvoiceTax,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )
                                : "-"}
                    </Grid>
                    <Grid item xs={6}>
                        <h4 style={{ margin: 0 }}>Amount</h4>
                    </Grid>
                    <Grid item xs={6} align="right">
                        {selectedInvoice != null
                            ? formatter.formatMoney(
                                selectedInvoice.total,
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )
                            : selectedInvoiceTotal
                                ? formatter.formatMoney(
                                    selectedInvoiceTotal,
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )
                                : "-"}
                    </Grid>
                    {selectedInvoice != null ? (
                        <Grid item xs={6}>
                            <h4 style={{ margin: 0 }}>Paid</h4>
                        </Grid>
                    ) : null}
                    {selectedInvoice != null ? (
                        <Grid item xs={6} align="right">
                            -(
                            {formatter.formatMoney(
                                selectedInvoice.invoice_payments
                                    .filter((record) => record.status == "Success")
                                    .reduce((sum, record) => sum + record.amount, 0),
                                authInfo.tenant.locale.currency_code,
                                authInfo.tenant.locale.precision
                            )}
                            )
                        </Grid>
                    ) : null}
                    {selectedInvoice != null ? (
                        <Grid item xs={6}>
                            <h4 style={{ margin: 0 }}>Due Amount</h4>
                        </Grid>
                    ) : null}
                    {selectedInvoice != null ? (
                        <Grid item xs={6} align="right">
                            {selectedInvoice != null
                                ? formatter.formatMoney(
                                    selectedInvoice.total -
                                    selectedInvoice.invoice_payments
                                        .filter((record) => record.status == "Success")
                                        .reduce((sum, record) => sum + record.amount, 0),
                                    authInfo.tenant.locale.currency_code,
                                    authInfo.tenant.locale.precision
                                )
                                : selectedInvoiceTotal
                                    ? formatter.formatMoney(
                                        selectedInvoiceTotal,
                                        authInfo.tenant.locale.currency_code,
                                        authInfo.tenant.locale.precision
                                    )
                                    : "-"}
                        </Grid>
                    ) : null}
                </Grid>
            </DialogContent>
            <DialogActions>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<PrintIcon />}
                        >
                            Print
                        </Button>
                    )}
                    content={() => componentRef}
                />
            </DialogActions>
        </Dialog>
    );
};

const BookingEditForm = (props) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    const classes = useStyles();
    const translate = useTranslate();
    const notify = useNotify();
    const dataProvider = useContext(DataProviderContext);

    const { source, ...rest } = props;
    const [openAccommodation, setOpenAccommodation] = React.useState(false);
    const [selectedAccommodation, setSelectedAccommodation] =
        React.useState(null);
    const [openOrder, setOpenOrder] = React.useState(false);
    const [selectedOrder, setSelectedOrder] = React.useState(null);
    const [openDeposit, setOpenDeposit] = React.useState(false);
    const [selectedDeposit, setSelectedDeposit] = React.useState(null);
    const [openRefund, setOpenRefund] = React.useState(false);
    const [selectedRefund, setSelectedRefund] = React.useState(null);
    const [selectedRefundDeposit, setSelectedRefundDeposit] =
        React.useState(null);
    const [selectedRefundOrder, setSelectedRefundOrder] = React.useState(null);
    const [openInvoice, setOpenInvoice] = React.useState(false);
    const [selectedInvoice, setSelectedInvoice] = React.useState(null);
    const [availableRoomType, setAvailableRoomType] = React.useState([]);
    const [availableRoom, setAvailableRoom] = React.useState([]);
    const [roomTypes, setRoomTypes] = React.useState([]);
    const [availableService, setAvailableService] = React.useState([]);
    const [booking, setBooking] = React.useState(null);
    const [summaryAmount, setSummaryAmount] = React.useState(0);
    const [summaryDeposit, setSummaryDeposit] = React.useState(0);
    const [summaryPaid, setSummaryPaid] = React.useState(0);
    const [selectedOrderIds, setSelectedOrderIds] = React.useState([]);
    const [openPayment, setOpenPayment] = React.useState(false);
    const [selectedPayment, setSelectedPayment] = React.useState(null);
    const [openInvoiceDetail, setOpenInvoiceDetail] = React.useState(false);
    const [selectedInvoiceSubtotal, setSelectedInvoiceSubtotal] =
        React.useState(null);
    const [selectedInvoiceTax, setSelectedInvoiceTax] = React.useState(null);
    const [selectedInvoiceTotal, setSelectedInvoiceTotal] = React.useState(null);

    useEffect(() => {
        authApi.getGuestToken().then(
            (authData) => {
                paymentApi.midtransGetConfig().then(
                    (paymentData) => {
                        const midtransScriptUrl = paymentData.tdsJsUrl;
                        const myMidtransClientKey = paymentData.clientKey;
                        const myMidtransEnvironment = paymentData.environment;

                        let scriptTag = document.createElement("script");
                        scriptTag.id = "midtrans-script";
                        scriptTag.src = midtransScriptUrl;
                        scriptTag.async = true;
                        scriptTag.setAttribute("data-client-key", myMidtransClientKey);
                        scriptTag.setAttribute("data-environment", myMidtransEnvironment);

                        document.body.appendChild(scriptTag);
                    },
                    (error) => {
                        notify("Fail to get payment info");
                    }
                );
            },
            (error) => {
                notify("Fail to get token info");
            }
        );

        setBooking(props.record);

        let summaryAmount = props.record.orders
            .filter((record) => record.status != "Cancelled")
            .reduce((sum, record) => sum + record.total_price, 0);

        setSummaryAmount(summaryAmount);

        let summaryDeposit = props.record.deposits
            .filter((record) => record.status != "Cancelled")
            .reduce(
                (sum, record) =>
                    sum +
                    record.amount -
                    record.invoice_payments.reduce(
                        (sumIP, recordIP) => sumIP + recordIP.amount,
                        0
                    ) -
                    record.refunds
                        .filter((record) => record.status != "Cancelled")
                        .reduce((sumRF, recordRF) => sumRF + recordRF.amount, 0),
                0
            );

        setSummaryDeposit(summaryDeposit);

        let summaryPaid = 0;
        props.record.invoices.forEach((invoice) => {
            summaryPaid += invoice.invoice_payments
                .filter((record) => record.status == "Success")
                .reduce((sum, record) => sum + record.amount, 0);
        });

        setSummaryPaid(summaryPaid);

        var request = {
            tenant_id: authInfo.tenant.id,
        };
        dataProvider
            .getList("room_types", { filter: { ...request } })
            .then(({ data }) => {
                setRoomTypes(data);
            })
            .catch((error) => {
                notify(error);
            });
        dataProvider
            .getList("services", { filter: { ...request } })
            .then(({ data }) => {
                setAvailableService(data);
            })
            .catch((error) => {
                notify(error);
            });
    }, []);

    const refreshBooking = () => {
        dataProvider
            .getOne("bookings", { id: props.record.id })
            .then(({ data }) => {
                setBooking(data);

                let summaryAmount = data.orders
                    .filter((record) => record.status != "Cancelled")
                    .reduce((sum, record) => sum + record.total_price, 0);

                setSummaryAmount(summaryAmount);
                let a = 0;
                
                let summaryDeposit = data.deposits
                .filter((record) => record.status != "Cancelled")
                .reduce(
                    (sum, record) =>
                        sum +
                        record.amount -
                        record.invoice_payments.reduce(
                            (sumIP, recordIP) => sumIP + recordIP.amount,
                            0
                        ) -
                        record.refunds
                            .filter((record) => record.status != "Cancelled")
                            .reduce((sumRF, recordRF) => sumRF + recordRF.amount, 0),
                    0
                );
    
                setSummaryDeposit(summaryDeposit);

                let summaryPaid = 0;
                data.invoices.forEach((invoice) => {
                    summaryPaid += invoice.invoice_payments
                        .filter((record) => record.status == "Success")
                        .reduce((sum, record) => sum + record.amount, 0);
                });

                setSummaryPaid(summaryPaid);
            })
            .catch((error) => {
                notify(error);
            });
    };

    const refreshSelectedInvoice = (invoiceId) => {
        dataProvider
            .getOne("invoices", { id: invoiceId })
            .then(({ data }) => {
                setSelectedInvoice(data);
            })
            .catch((error) => {
                notify(error);
            });
    };

    return (
        <FormWithRedirect
            {...props}
            render={(formProps) => (
                <form>
                    <AccommodationModal
                        openAccommodation={openAccommodation}
                        setOpenAccommodation={setOpenAccommodation}
                        selectedAccommodation={selectedAccommodation}
                        availableRoomType={availableRoomType}
                        setAvailableRoomType={setAvailableRoomType}
                        availableRoom={availableRoom}
                        setAvailableRoom={setAvailableRoom}
                        booking={booking}
                        refreshBooking={refreshBooking}
                        roomTypes={roomTypes}
                    />
                    <DepositModal
                        openDeposit={openDeposit}
                        setOpenDeposit={setOpenDeposit}
                        selectedDeposit={selectedDeposit}
                        booking={booking}
                        refreshBooking={refreshBooking}
                    />
                    <RefundModal
                        openRefund={openRefund}
                        setOpenRefund={setOpenRefund}
                        selectedRefund={selectedRefund}
                        selectedRefundDeposit={selectedRefundDeposit}
                        selectedRefundOrder={selectedRefundOrder}
                        booking={booking}
                        refreshBooking={refreshBooking}
                    />
                    <OrderModal
                        openOrder={openOrder}
                        setOpenOrder={setOpenOrder}
                        selectedOrder={selectedOrder}
                        availableService={availableService}
                        booking={booking}
                        refreshBooking={refreshBooking}
                    />
                    <InvoiceModal
                        openInvoice={openInvoice}
                        setOpenInvoice={setOpenInvoice}
                        selectedInvoice={selectedInvoice}
                        availableService={availableService}
                        booking={booking}
                        selectedOrderIds={selectedOrderIds}
                        setSelectedOrderIds={setSelectedOrderIds}
                        setOpenPayment={setOpenPayment}
                        setOpenInvoiceDetail={setOpenInvoiceDetail}
                        refreshBooking={refreshBooking}
                        selectedInvoiceSubtotal={selectedInvoiceSubtotal}
                        setSelectedInvoiceSubtotal={setSelectedInvoiceSubtotal}
                        selectedInvoiceTax={selectedInvoiceTax}
                        setSelectedInvoiceTax={setSelectedInvoiceTax}
                        selectedInvoiceTotal={selectedInvoiceTotal}
                        setSelectedInvoiceTotal={setSelectedInvoiceTotal}
                    />
                    <PaymentModal
                        openPayment={openPayment}
                        setOpenPayment={setOpenPayment}
                        selectedPayment={selectedPayment}
                        availableService={availableService}
                        booking={booking}
                        selectedInvoice={selectedInvoice}
                        refreshBooking={refreshBooking}
                        refreshSelectedInvoice={refreshSelectedInvoice}
                    />
                    <InvoiceDetailModal
                        openInvoiceDetail={openInvoiceDetail}
                        setOpenInvoiceDetail={setOpenInvoiceDetail}
                        selectedInvoice={selectedInvoice}
                        selectedOrderIds={selectedOrderIds}
                        booking={booking}
                        selectedInvoiceSubtotal={selectedInvoiceSubtotal}
                        setSelectedInvoiceSubtotal={setSelectedInvoiceSubtotal}
                        selectedInvoiceTax={selectedInvoiceTax}
                        setSelectedInvoiceTax={setSelectedInvoiceTax}
                        selectedInvoiceTotal={selectedInvoiceTotal}
                        setSelectedInvoiceTotal={setSelectedInvoiceTotal}
                    />
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <BookingInfo />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <GuestInfo />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <BalanceInfo
                                    booking={booking}
                                    summaryAmount={summaryAmount}
                                    summaryDeposit={summaryDeposit}
                                    summaryPaid={summaryPaid}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AccommodationInfo
                                    setOpenAccommodation={setOpenAccommodation}
                                    setSelectedAccommodation={setSelectedAccommodation}
                                    setAvailableRoom={setAvailableRoom}
                                    booking={booking}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <DepositInfo
                                    setOpenDeposit={setOpenDeposit}
                                    setOpenRefund={setOpenRefund}
                                    setSelectedDeposit={setSelectedDeposit}
                                    setSelectedRefund={setSelectedRefund}
                                    setSelectedRefundDeposit={setSelectedRefundDeposit}
                                    setSelectedRefundOrder={setSelectedRefundOrder}
                                    selectedDeposit={selectedDeposit}
                                    booking={booking}
                                    refreshBooking={refreshBooking}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <RefundInfo
                                    setOpenRefund={setOpenRefund}
                                    setSelectedRefund={setSelectedRefund}
                                    booking={booking}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <OrderInfo
                                    setOpenOrder={setOpenOrder}
                                    setOpenRefund={setOpenRefund}
                                    setSelectedOrder={setSelectedOrder}
                                    setSelectedRefund={setSelectedRefund}
                                    setSelectedRefundDeposit={setSelectedRefundDeposit}
                                    setSelectedRefundOrder={setSelectedRefundOrder}
                                    booking={booking}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <InvoiceInfo
                                    setOpenInvoice={setOpenInvoice}
                                    setSelectedInvoice={setSelectedInvoice}
                                    setSelectedOrderIds={setSelectedOrderIds}
                                    booking={booking}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </form>
            )}
        />
    );
};

export default BookingEditForm;

