import { buildFields } from "ra-data-hasura";
import { GET_LIST, GET_ONE } from "react-admin";
import { ResourceType, FetchType } from "ra-data-hasura";

import { GET_LIST_COMPANIES } from "./queries/companies";
import { GET_LIST_ROOM_TYPES, GET_ONE_ROOM_TYPES } from "./queries/room_types";
import { GET_LIST_ROOM_RATES, GET_ONE_ROOM_RATES } from "./queries/room_rates";
import { GET_LIST_ROOMS } from "./queries/rooms";
import { GET_LIST_BOOKINGS, GET_ONE_BOOKINGS } from "./queries/bookings";
import {
  GET_LIST_ACCOMMODATIONS,
  GET_ONE_ACCOMMODATIONS,
} from "./queries/accommodations";
import {
  GET_LIST_ROOM_TYPE_RATES,
  GET_ONE_ROOM_TYPE_RATES,
} from "./queries/room_type_rates";
import { GET_LIST_GUESTS } from "./queries/guests";
import {
  GET_LIST_MAINTENANCES,
  GET_ONE_MAINTENANCES,
} from "./queries/maintenances";
import { GET_LIST_INVOICES, GET_ONE_INVOICES } from "./queries/invoices";
import { GET_LIST_ORDERS, GET_ONE_ORDERS } from "./queries/orders";
import { GET_LIST_USERS, GET_ONE_USERS } from "./queries/users";
import { GET_LIST_DEPOSITS, GET_ONE_DEPOSITS } from "./queries/deposits";
import { GET_LIST_REFUNDS, GET_ONE_REFUNDS } from "./queries/refunds";
import { GET_LIST_TENANTS } from "./queries/tenants";
import {
  GET_LIST_CM_CLIENTS,
  GET_ONE_CM_CLIENTS,
} from "./queries/cm_accounts";
import {
  GET_LIST_CM_2FA_SESSIONS,
  GET_ONE_CM_2FA_SESSIONS,
} from "./queries/cm_2fa_sessions";

/**
 * Extracts just the fields from a GraphQL AST.
 * @param {GraphQL AST} queryAst
 */
const extractFieldsFromQuery = (queryAst) =>
  queryAst.definitions[0].selectionSet.selections;

// An object of all the custom queries we have defined.
const CUSTOM_QUERIES = {
  companies: {
    [GET_LIST]: GET_LIST_COMPANIES,
  },
  room_types: {
    [GET_LIST]: GET_LIST_ROOM_TYPES,
    [GET_ONE]: GET_ONE_ROOM_TYPES,
  },
  room_rates: {
    [GET_LIST]: GET_LIST_ROOM_RATES,
    [GET_ONE]: GET_ONE_ROOM_RATES,
  },
  rooms: {
    [GET_LIST]: GET_LIST_ROOMS,
  },
  bookings: {
    [GET_LIST]: GET_LIST_BOOKINGS,
    [GET_ONE]: GET_ONE_BOOKINGS,
  },
  cm_accounts: {
    [GET_LIST]: GET_LIST_CM_CLIENTS,
    [GET_ONE]: GET_ONE_CM_CLIENTS,
  },
  cm_2fa_sessions: {
    [GET_LIST]: GET_LIST_CM_2FA_SESSIONS,
    [GET_ONE]: GET_ONE_CM_2FA_SESSIONS,
  },
  accommodations: {
    [GET_LIST]: GET_LIST_ACCOMMODATIONS,
    [GET_ONE]: GET_ONE_ACCOMMODATIONS,
  },
  room_type_rates: {
    [GET_LIST]: GET_LIST_ROOM_TYPE_RATES,
    [GET_ONE]: GET_ONE_ROOM_TYPE_RATES,
  },
  guests: {
    [GET_LIST]: GET_LIST_GUESTS,
  },
  invoices: {
    [GET_LIST]: GET_LIST_INVOICES,
    [GET_ONE]: GET_ONE_INVOICES,
  },
  orders: {
    [GET_LIST]: GET_LIST_ORDERS,
    [GET_ONE]: GET_ONE_ORDERS,
  },
  maintenances: {
    [GET_LIST]: GET_LIST_MAINTENANCES,
    [GET_ONE]: GET_ONE_MAINTENANCES,
  },
  users: {
    [GET_LIST]: GET_LIST_USERS,
    [GET_ONE]: GET_ONE_USERS,
  },
  deposits: {
    [GET_LIST]: GET_LIST_DEPOSITS,
    [GET_ONE]: GET_ONE_DEPOSITS,
  },
  refunds: {
    [GET_LIST]: GET_LIST_REFUNDS,
    [GET_ONE]: GET_ONE_REFUNDS,
  },
  tenants: {
    [GET_LIST]: GET_LIST_TENANTS,
  },
};

// Function which defines query overrides for specific resources/fetchTypes.
const customBuildFields = (type, fetchType) => {
  const resourceName = type.name;

  // First check if the resource has any custom queries defined.
  const resourceCustomQueries = CUSTOM_QUERIES[resourceName];

  // If this specific query i.e. resource and fetchType has a custom query, extract the fields from it.
  if (resourceCustomQueries && resourceCustomQueries[fetchType]) {
    return extractFieldsFromQuery(resourceCustomQueries[fetchType]);
  }

  // No custom query defined, so use the default query fields (all, but none related/nested).
  return buildFields(type, fetchType);
};

export default customBuildFields;
