import gql from 'graphql-tag';

const GET_LIST_ACCOMMODATIONS = gql`
{
  booking_id
  check_in_date
  check_out_date
  created_at
  id
  room {
    floor
    id
    name
    status
  }
  room_id
  room_type_id
  room_type_rate_id
  status
  updated_at
  e_accommodation_status {
    comment
    id
  }
  room_type {
    name
  }
  room_type_rate {
    room_rate {
      cancellation_policy {
        after_period_cancel_charge
        created_at
        id
        name
        no_show_cancel_charge
        period_cancel_without_charge
        updated_at
      }
      cancellation_policy_id
      name
      meal {
        name
        created_at
        id
        price
        service_charge
        updated_at
      }
      meal_id
    }
    id
    price
    room_rate_id
    room_type_id
    service_charge
    updated_at
    created_at
  }
  booking {
    booking_no
    accommodations(order_by: {created_at: desc}) {
      booking_id
      check_in_date
      check_out_date
      created_at
      id
      room {
        floor
        id
        name
        status
      }
      room_id
      room_type_id
      room_type_rate_id
      status
      updated_at
      e_accommodation_status {
        comment
        id
      }
      room_type {
        name
        room_type_rates {
          created_at
          id
          price
          room_rate_id
          room_type_id
          service_charge
          updated_at
          room_rate {
            updated_at
            name
            meal_id
            id
            created_at
            cancellation_policy_id
            cancellation_policy {
              after_period_cancel_charge
              created_at
              id
              name
              no_show_cancel_charge
              period_cancel_without_charge
              updated_at
            }
            meal {
              created_at
              id
              name
              price
              service_charge
              updated_at
            }
          }
        }
        rooms {
          created_at
          floor
          id
          name
          room_type_id
          status
          clean_status
          updated_at
        }
      }
      room_type_rate {
        room_rate {
          cancellation_policy {
            after_period_cancel_charge
            created_at
            id
            name
            no_show_cancel_charge
            period_cancel_without_charge
            updated_at
          }
          cancellation_policy_id
          name
          meal {
            name
            created_at
            id
            price
            service_charge
            updated_at
          }
          meal_id
        }
        id
        price
        room_rate_id
        room_type_id
        service_charge
        updated_at
        created_at
      }
    }
    orders(order_by: {created_at: desc})  {
      accommodation_id
      booking_id
      invoice_id
      created_at
      description
      id
      order_no
      order_type
      price
      qty
      updated_at
      total_price
      status
      service_id
      service_charge
      e_order_status {
        comment
        id
      }
      accommodation {
        room {
          name
        }
        id
      }
    }
    guest {
      bleudine_user_id
      zip_code
      updated_at
      title
      region
      phone_no
      nationality
      last_name
      id
      gender
      first_name
      display_name
      email
      e_title {
        comment
        id
      }
      e_gender {
        comment
        id
      }
      e_nationality {
        comment
        id
      }
      e_document_type {
        comment
        id
      }
      e_country {
        comment
        id
      }
      document_type
      document_no
      document_front_image_url
      document_expiry
      document_back_image_url
      created_at
      country
      comment
      city
      birth_date
      address_2
      address_1
    }
  }
}
`;

const GET_ONE_ACCOMMODATIONS = gql`
{
  booking_id
  check_in_date
  check_out_date
  created_at
  id
  room {
    floor
    id
    name
    status
  }
  room_id
  room_type_id
  room_type_rate_id
  status
  updated_at
  e_accommodation_status {
    comment
    id
  }
  room_type {
    name
  }
  room_type_rate {
    room_rate {
      cancellation_policy {
        after_period_cancel_charge
        created_at
        id
        name
        no_show_cancel_charge
        period_cancel_without_charge
        updated_at
      }
      cancellation_policy_id
      name
      meal {
        name
        created_at
        id
        price
        service_charge
        updated_at
      }
      meal_id
    }
    id
    price
    room_rate_id
    room_type_id
    service_charge
    updated_at
    created_at
  }
  booking {
    booking_no
    accommodations(order_by: {created_at: desc}) {
      booking_id
      check_in_date
      check_out_date
      created_at
      id
      room {
        floor
        id
        name
        status
      }
      room_id
      room_type_id
      room_type_rate_id
      status
      updated_at
      e_accommodation_status {
        comment
        id
      }
      room_type {
        name
        room_type_rates {
          created_at
          id
          price
          room_rate_id
          room_type_id
          service_charge
          updated_at
          room_rate {
            updated_at
            name
            meal_id
            id
            created_at
            cancellation_policy_id
            cancellation_policy {
              after_period_cancel_charge
              created_at
              id
              name
              no_show_cancel_charge
              period_cancel_without_charge
              updated_at
            }
            meal {
              created_at
              id
              name
              price
              service_charge
              updated_at
            }
          }
        }
        rooms {
          created_at
          floor
          id
          name
          room_type_id
          status
          clean_status
          updated_at
        }
      }
      room_type_rate {
        room_rate {
          cancellation_policy {
            after_period_cancel_charge
            created_at
            id
            name
            no_show_cancel_charge
            period_cancel_without_charge
            updated_at
          }
          cancellation_policy_id
          name
          meal {
            name
            created_at
            id
            price
            service_charge
            updated_at
          }
          meal_id
        }
        id
        price
        room_rate_id
        room_type_id
        service_charge
        updated_at
        created_at
      }
    }
    orders(order_by: {created_at: desc})  {
      accommodation_id
      booking_id
      invoice_id
      created_at
      description
      id
      order_no
      order_type
      price
      qty
      updated_at
      total_price
      status
      service_id
      service_charge
      e_order_status {
        comment
        id
      }
      accommodation {
        room {
          name
        }
        id
      }
    }
    guest {
      bleudine_user_id
      zip_code
      updated_at
      title
      region
      phone_no
      nationality
      last_name
      id
      gender
      first_name
      display_name
      email
      e_title {
        comment
        id
      }
      e_gender {
        comment
        id
      }
      e_nationality {
        comment
        id
      }
      e_document_type {
        comment
        id
      }
      e_country {
        comment
        id
      }
      document_type
      document_no
      document_front_image_url
      document_expiry
      document_back_image_url
      created_at
      country
      comment
      city
      birth_date
      address_2
      address_1
    }
  }
}
`;

export{GET_LIST_ACCOMMODATIONS, GET_ONE_ACCOMMODATIONS}
