import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./resources/configuration/Configuration";
import ChangePassword from "./resources/password/ChangePassword";
import FrontDesk from "./resources/dashboard/FrontDesk";
import Housekeeping from "./resources/housekeeping/Housekeeping";
import ConnectRideum from "./resources/connect/ConnectRideum";

//Reports
import AccountingReport from "./resources/reports/AccountingReport.js";
import BookingBalanceReport from "./resources/reports/BookingBalanceReport";
import OccupancyReport from "./resources/reports/OccupancyReport.js";
import SalesReport from "./resources/reports/SalesReport.js";
import DynamicRoomRateList from "./resources/dynamicRates/DynamicRoomRateList.jsx";

//ChannelManage
//import ChannelManagerAccount from "./resources/channelmanager/ChannelManagerAccounts.js";

export default [
  <Route exact path="/configuration" component={Configuration} />,
  <Route exact path="/password/change" component={ChangePassword} />,
  <Route exact path="/frontDesk" component={FrontDesk} />,
  <Route exact path="/housekeeping" component={Housekeeping} />,
  <Route exact path="/connect-rideum" component={ConnectRideum} />,
  //Reports
  <Route exact path="/accounting-report" component={AccountingReport} />,
  <Route
    exact
    path="/booking-balance-report"
    component={BookingBalanceReport}
  />,
  <Route exact path="/occupancy-report" component={OccupancyReport} />,
  <Route exact path="/sales-report" component={SalesReport} />,
  <Route exact path="/dynamic_rates" component={DynamicRoomRateList}/>

  //Channel Manager
  /*   <Route
    exact
    path="/channel-manager-accounts"
    component={ChannelManagerAccount}
  />, */
];
