import * as React from "react";
import {
  List,
  Datagrid,
  FunctionField,
  useListContext,
  TopToolbar,
  ExportButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  sanitizeListRestProps,
  downloadCSV,
} from "react-admin";
import { invoiceStatus } from "../../models/invoiceStatus";
import { formatter } from "../../utils/formatter";
import Chip from "@material-ui/core/Chip";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import { Button, InputAdornment } from "@material-ui/core";
import moment from "moment";
import jsonExport from "jsonexport/dist";


const filters = [
  <TextInput
    type="number"
    source="invoice_no"
    label="Invoice No"
    alwaysOn
    resettable
    InputProps={{
      startAdornment: <InputAdornment>I</InputAdornment>,
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_invoice_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

const exporter = (records) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const forExport = records.map((record) => {
    return {
      invoice_no: formatter.invoiceNo(record.invoice_no),
      subtotal: formatter.formatMoney(
        record.subtotal,
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      tax: formatter.formatMoney(
        record.tax,
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      total: formatter.formatMoney(
        record.subtotal + record.tax,
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      created_date: moment(record.created_at).format("DD MMM YYYY hh:mm:ss A"),
      status: record.e_invoice_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: [
        "invoice_no",
        "subtotal",
        "tax",
        "total",
        "created_date",
        "status",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "invoices");
    }
  );
};

const InvoiceList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  return (
    <List
      filters={filters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id, is_valid: true }}
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <FunctionField
          sortBy="invoice_no"
          label="Invoice No"
          render={(record) => formatter.invoiceNo(record.invoice_no)}
        />
        <FunctionField
          sortBy="subtotal"
          label="Subtotal"
          render={(record) =>
            formatter.formatMoney(
              record.subtotal,
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          label="Tax"
          render={(record) =>
            formatter.formatMoney(
              record.tax,
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          label="Total"
          render={(record) =>
            formatter.formatMoney(
              record.subtotal + record.tax,
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          sortBy="created_at"
          label="Created Date"
          render={(record) =>
            moment(record.created_at).format("DD MMM YYYY hh:mm:ss A")
          }
        />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_invoice_status.comment}
              style={{
                color: "white",
                background: invoiceStatus.getColor(record.e_invoice_status.id),
              }}
            />
          )}
        />
        <FunctionField
          render={(record) => (
            <Button
              style={{ border: "none", float: "right" }}
              startIcon={<EditIcon />}
              variant="outlined"
              color="primary"
              href={`/bookings/${record.booking_id}`}
              target="_blank"
            >
              View Booking
            </Button>
          )}
        />
        {/* <EditButton basePath="/invoices" /> */}
      </Datagrid>
    </List>
  );
}

export default InvoiceList;
