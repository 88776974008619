import moment from "moment";


const DATE_FORMAT = "YYYY-MM-DD"
/**
    * typedef {{
        room_type_rate_id:string
         room_type_id: string
         qty:number
        price: number
        service_charge: number
        additional_adult_charge: number
        additional_adult:number
    }} RoomType
    * @typedef {Array<RoomType>} SelectedRoomType
    * @typedef {Object.<string, Array<{price:number, date:string}>} DynamicRates
    * @typedef {{date: string, price: number}} BreakdownEntry
    * @typedef {Object.<string, Array<BreakdownEntry>>} Breakdown
    * **/
/**
@param {SelectedRoomType} selectedRoomType a collection of selected room type
@param {Array<string>} selectedDates array of dates from check_in_date to check_out_date (excluded)
@param {DynamicRates} dynamicRates an object with room_type_rate_id as key and price and date as value
@returns {Breakdown} the price breakdown per room_type_rate_id per night
**/
export function computeBreakdown(selectedRoomType, selectedDates, dynamicRates) {
    return selectedRoomType.reduce((acc, item) => {
        let additionalAdultCharges = item.additional_adult * item.additional_adult_charge;
        let entry = selectedDates.reduce((dateAcc, date) => {
            let price = item.price;
            let dynamicRate = dynamicRates[item.room_type_rate_id];
            if (dynamicRate) {
                let rate = dynamicRate.find(r => r.date === date);
                if (rate) {
                    price = rate.price;
                }
            }
            return [...dateAcc, { date: date, price: price + additionalAdultCharges }]
        }, [])
        return { ...acc, [item.room_type_rate_id]: entry }
    }, {})

}

/**
    * @param {string} fromDate
    * @param {string} toDate
    * @returns {Array<string>} array of from start date to end date - 1 day
* */
export function dateSequence(fromDate, toDate) {
    if (moment(fromDate, DATE_FORMAT).isSameOrAfter(moment(toDate, DATE_FORMAT))) {
        throw new Error(`${fromDate} is same or after ${toDate}`)
    }
    let start = fromDate;
    let result = [];

    while (start != toDate) {
        result.push(start);
        start = moment(start, DATE_FORMAT).add(1, "day").format(DATE_FORMAT)
    }
    return result

}

function surcharge({ price, additional_adult, additional_adult_charge }) {
    if (additional_adult < 0 || additional_adult_charge < 0) {
        throw Error("Invalid configuration, negative additional adult or addtional adult charges")
    }
    if (additional_adult) {
        if (isNaN(additional_adult)) {
            throw Error("Additional adult is not a number")
        }
        if (additional_adult_charge) {
            if (isNaN(additional_adult_charge)) {
                throw Error("Additional adult charge is not a number")
            }
            return price + additional_adult * additional_adult_charge;
        }
    } return price;
}

/**
    * @param {DynamicRates} dynamicRates
    * @param {Array<string>} selectedDates
    * @param {SelectedRoomType} selectedRoomType
    * @returns {number} total price for the selected room
**/
export function computeTotalRoomCharges(selectedRoomType, selectedDates, dynamicRates) {


    if (Object.keys(dynamicRates).length == 0) {
        return selectedDates.length * selectedRoomType.reduce((acc, item) => acc + surcharge(item) * item.qty, 0)
    }
    return selectedRoomType.reduce((acc, item) => {
        let rates = dynamicRates[item.room_type_rate_id];
        if (rates && rates.length > 0) {
            let staticRateNight = selectedDates.length - rates.length;
            let staticSubtotal = staticRateNight * surcharge(item) * item.qty;
            let dynamicSubtotal = rates.reduce((acc, rate) => acc + item.qty * surcharge({ price: rate.price, additional_adult_charge: item.additional_adult_charge, additional_adult: item.additional_adult }), 0)
            return acc + staticSubtotal + dynamicSubtotal;
        }
        return acc + selectedDates.length * surcharge(item) * item.qty;

    }, 0)

}

/**
 * @param {SelectedRoomType} selectedRoomType
    * @param {Array<string>} selectedDates
    * @param {DynamicRates} dynamicRates
    * @returns {number} total service_charges for the selected room
**/
export function computeTotalServiceCharges(selectedRoomType, selectedDates, dynamicRates) {
    if (Object.keys(dynamicRates).length == 0) {
        return selectedDates.length * selectedRoomType.reduce((acc, item) => acc + item.price * item.qty, 0)
    }
    return selectedRoomType.reduce((acc, item) => {
        let rates = dynamicRates[item.room_type_rate_id];
        if (rates && rates.length > 0) {
            let staticRateNight = selectedDates.length - rates.length;
            let staticSubtotal = staticRateNight * surcharge(item) * item.qty * item.service_charge;
            let dynamicSubtotal = rates.reduce((acc, rate) => acc + item.qty * surcharge({price: rate.price, additional_adult_charge: item.additional_adult_charge, additional_adult: item.additional_adult}) * item.service_charge, 0)
            return acc + staticSubtotal + dynamicSubtotal;
        }
        return acc + selectedDates.length * surcharge(item) * item.service_charge * item.qty;

    }, 0)
}
/**
    * @typedef {{room_type_rate_id: string, room_type_id: string, check_in_date: string, check_out_date: string,additional_adult: number}} Accommodation
    * @param {Accommodation} accommodation
    *
    * @typedef {{room_type_id: string, price: number, additional_adult_charge:number, service_charge: number, room_type_rate_adjustments: Array<{id: string, date: string, value: number}>}} RoomTypeRate
    * @param {RoomTypeRate} roomTypeRate
    *
**/
export function computeRoomChargesForAccommodation(accommodation, roomTypeRate) {
    let dates = dateSequence(accommodation.check_in_date, accommodation.check_out_date)
    let additionalAdultSubtotal = accommodation.additional_adult * roomTypeRate.additional_adult_charge * dates.length;
    let dynamicRates = roomTypeRate.room_type_rate_adjustments || [];
    if (dynamicRates.length == 0) {
        if (accommodation.additional_adult) {
            return dates.length * roomTypeRate.price + additionalAdultSubtotal;
        }
        return dates.length * roomTypeRate.price;
    }
    let matchingRates = dynamicRates.filter((rate) => dates.indexOf(rate.date) != -1)
    let staticRateQty = dates.length - matchingRates.length;
    let staticRateSubtotal = staticRateQty * roomTypeRate.price;
    let dynamicRateSubtotal = matchingRates.reduce((acc, rate) => acc + rate.value, 0);

    if (accommodation.additional_adult) {
        return staticRateSubtotal + dynamicRateSubtotal + additionalAdultSubtotal;
    } else {
        return staticRateSubtotal + dynamicRateSubtotal;
    }
}



export default { computeBreakdown, dateSequence, computeTotalRoomCharges, computeTotalServiceCharges }
