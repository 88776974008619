import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  DateInput,
  TextInput,
  SelectInput,
  ImageInput,
  ImageField,
  SaveButton,
  DeleteButton,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  email,
  useTranslate,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  FormDataConsumer
} from 'react-admin';
import { Typography, Box } from '@material-ui/core';
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import ImageUploader from "../components/ImageUploader";
import CustomSaveButton from './CustomSaveButton';
import { authApi } from "../../api/authApi";
import { useNotify, useRedirect } from "react-admin";
import SearchIcon from "@material-ui/icons/Search";
import { bleudineMemberApi } from "../../api/bleudineMemberApi";
import moment from "moment";


const validateFirstName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
}));

const GuestForm = props => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const { source, ...rest } = props;
  const [documentFrontImage, setDocumentFrontImage] = React.useState([]);
  const [documentBackImage, setDocumentBackImage] = React.useState([]);
  const [bleudineMembership, setBleudineMembership] = React.useState(null);
  const subscriptionSummary = (JSON.parse(localStorage.getItem("auth"))).merchantCore.subscriptionSummary
  useEffect(() => {
    authApi.getGuestToken().then(
      (authData) => { },
      (error) => { notify("Fail to get token info"); })

    if (props.record != null && props.record.document_front_image_url) {
      setDocumentFrontImage([{ data_url: props.record.document_front_image_url }]);
    }
    if (props.record != null && props.record.document_back_image_url) {
      setDocumentBackImage([{ data_url: props.record.document_back_image_url }]);
    }
    if (props.record != null && props.record.bleudine_user_id) {
      bleudineMemberApi.getById(props.record.bleudine_user_id).then(
        (data) => {
          setBleudineMembership(data);
        },
        (error) => { notify("Fail to get member info"); })
    }
  }, []);


  const searchBleudineMembership = (searchKey) => {
    if (searchKey == null || searchBleudineMembership == "") {
      alert("Please enter search registered email or phone number to search");
    } else {
      bleudineMemberApi.getBySearch(searchKey).then(
        (data) => {
          setBleudineMembership(data);
        },
        (error) => { notify("Fail to get member info"); })
    }
  }

  const removeBleudineMembership = () => {
    setBleudineMembership(null);
  }

  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textBold} gutterBottom>
                    {translate("ra.label.personal_info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formGrid} style={{ display: "none" }}>
                  <TextInput
                    className={classes.formInput}
                    source="tenant_id"
                    label="Tenant ID"
                    fullWidth
                    defaultValue={authInfo.tenant.id}
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="first_name"
                    validate={validateFirstName}
                    label="ra.column.first_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="last_name"
                    label="ra.column.last_name"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="gender"
                    reference="e_genders"
                    sort={{ field: "comment", order: "ASC" }}
                    label="ra.column.gender"
                    fullWidth
                  >
                    <SelectInput
                      optionText="comment"
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="title"
                    reference="e_titles"
                    sort={{ field: "comment", order: "ASC" }}
                    label="ra.column.title"
                    fullWidth
                  >
                    <SelectInput
                      optionText="comment"
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="nationality"
                    reference="e_nationalities"
                    sort={{ field: "comment", order: "ASC" }}
                    filterToQuery={(searchText) => ({ comment: searchText })}
                    label="ra.column.nationality"
                    fullWidth
                  >
                    <AutocompleteInput
                      optionText="comment"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="birth_date"
                    label="ra.column.birth_date"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textBold} gutterBottom>
                    {translate("ra.label.contact_info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="email"
                    type="email"
                    validate={validateEmail}
                    label="ra.column.email"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <IntlPhoneInput
                    className={classes.formInput}
                    resource="guests"
                    source="phone_no"
                    label={translate("ra.column.phone_no")}
                    {...rest}
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    multiline
                    fullWidth
                    resource="guests"
                    source="address_1"
                    label="ra.column.address_1"
                  />
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    multiline
                    fullWidth
                    resource="guests"
                    source="address_2"
                    label="ra.column.address_2"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="zip_code"
                    label="ra.column.zip_code"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="region"
                    label="ra.column.region"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="city"
                    label="ra.column.city"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="country"
                    reference="e_countries"
                    sort={{ field: "comment", order: "ASC" }}
                    filterToQuery={(searchText) => ({ comment: searchText })}
                    label="ra.column.country"
                    fullWidth
                  >
                    <AutocompleteInput
                      optionText="comment"
                      options={{ InputProps: { autoComplete: "no" } }}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h6" className={classes.textBold} gutterBottom>
                    {translate("ra.label.legal_info")}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.formGrid}>
                  <ReferenceInput
                    className={classes.formInput}
                    source="document_type"
                    reference="e_document_types"
                    sort={{ field: "comment", order: "ASC" }}
                    label="ra.column.document_type"
                    fullWidth
                  >
                    <SelectInput
                      optionText="comment"
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="document_no"
                    label="ra.column.document_no"
                  />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    resource="guests"
                    source="document_expiry"
                    label="ra.column.document_expiry"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                    Document Front Image
                  </Typography>
                  <ImageUploader
                    maxNumber={1}
                    images={documentFrontImage}
                    setImages={setDocumentFrontImage}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <Typography style={{ marginTop: 10, color: "#000000", opacity: "0.5" }} variant="caption" display="block" gutterBottom>
                    Document Back Image
                  </Typography>
                  <ImageUploader
                    maxNumber={1}
                    images={documentBackImage}
                    setImages={setDocumentBackImage}
                  />
                </Grid>
                {subscriptionSummary && subscriptionSummary.bleudashBleudineIntegration ?
                  (<div>
                    <Grid item xs={12}>
                      <Typography variant="h6" className={classes.textBold} gutterBottom>
                        Bleudine Membership Info
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={10} className={classes.formGrid}>
                      <TextInput
                        className={classes.formInput}
                        fullWidth
                        source="bleudine_search_key"
                        label="Enter registered email or phone number"
                      />
                    </Grid>
                    <Grid item xs={12} sm={2} className={classes.formGrid}>
                      <FormDataConsumer>
                        {({ formData, ...rest }) => (
                          <Button
                            variant="contained"
                            color="primary"
                            startIcon={<SearchIcon />}
                            className={classes.formInput}
                            onClick={() => { searchBleudineMembership(formData.bleudine_search_key) }}
                          >
                            Search
                          </Button>
                        )}
                      </FormDataConsumer>
                    </Grid>
                    {bleudineMembership != null ?
                      <Grid item xs={12} sm={12}>
                        <Card>
                          <CardContent>
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={6}>
                                <Typography className={classes.textBold} gutterBottom>
                                  Email
                                </Typography>
                                {bleudineMembership.email != null ? bleudineMembership.email : "-"}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography className={classes.textBold} gutterBottom>
                                  Phone Number
                                </Typography>
                                {bleudineMembership.phoneNumber != null ? bleudineMembership.phoneNumber : "-"}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography className={classes.textBold} gutterBottom>
                                  First Name
                                </Typography>
                                {bleudineMembership.firstName}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography className={classes.textBold} gutterBottom>
                                  Last Name
                                </Typography>
                                {bleudineMembership.lastName}
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography className={classes.textBold} gutterBottom>
                                  Date Registered
                                </Typography>
                                {moment(bleudineMembership.createdTime).format("DD MMM YYYY hh:mm:ss A")}
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Button variant="contained" style={{ backgroundColor: "darkred", color: "white" }} onClick={() => { removeBleudineMembership() }}>Clear</Button>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                      : null}
                    <Grid item xs={12} sm={12}>
                      <ReferenceInput
                        className={classes.formInput}
                        fullWidth
                        label="Status"
                        source="status"
                        reference="e_guest_status"
                        validate={[required()]}
                      >
                        <SelectInput optionText="comment" />
                      </ReferenceInput>
                    </Grid>
                  </div>
                  )
                  : null}






              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} documentFrontImage={documentFrontImage} documentBackImage={documentBackImage} bleudineMembership={bleudineMembership} />
                {/* {formProps.record.id != undefined ?
                  <DeleteButton record={formProps.record} />
                  : null} */}
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  )
}

export default GuestForm;