import React from "react";
import {
  FormWithRedirect,
  DateInput,
  TextInput,
  SelectInput,
  SaveButton,
  DeleteButton,
  required,
  minLength,
  maxLength,
  minValue,
  maxValue,
  number,
  email,
  useTranslate,
  ReferenceInput,
  FormDataConsumer,
  ReferenceField,
  TextField,
  FunctionField,
  SimpleShowLayout,
  AutocompleteInput,
  Toolbar,
} from "react-admin";
import { Typography, Box } from "@material-ui/core";
import { IntlPhoneInput } from "../components/IntlPhoneInput";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import { useState, useEffect, useContext } from "react";

import { Create } from "react-admin";
import GuestForm from "../guest/GuestCreate";
import { useQueryWithStore, Loading, Error } from "react-admin";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify, useRedirect } from "react-admin";
import { DataProviderContext } from "react-admin";
import { accommodationStatus } from "../../models/accommodationStatus";
import SaveIcon from "@material-ui/icons/Save";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ClearIcon from "@material-ui/icons/Clear";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Card, CardContent } from "@material-ui/core";
import { formatter } from "../../utils/formatter";


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

var today = new Date();
var tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);

const validateName = [required(), minLength(2), maxLength(25)];
const validateEmail = [required(), email()];

const guestOptionRenderer = (guest) => {
  return `${guest.first_name} ${guest.last_name}`;
};

const AccommodationForm = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const translate = useTranslate();
  const { source, ...rest } = props;
  const classes = useStyles();
  const notify = useNotify();
  const dataProvider = useContext(DataProviderContext);

  const [availableRoomType, setAvailableRoomType] = React.useState([]);
  const [rooms, setRooms] = React.useState([]);
  const [availableBooking, setAvailableBooking] = React.useState([]);
  const [accommodations, setAccommodations] = React.useState([]);
  const [maintenances, setMaintenances] = React.useState([]);

  function parseDate(str) {
    var mdy = str.split("-");
    return new Date(mdy[0], mdy[1] - 1, mdy[2]);
  }

  function datediff(first, second) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }

  useEffect(() => {
    var request = {
      tenant_id: authInfo.tenant.id,
    };
    dataProvider
      .getList("room_types", { filter: { ...request } })
      .then(({ data }) => {
        setAvailableRoomType(data);
      })
      .catch((error) => {
        notify(error);
      });
    dataProvider
      .getList("rooms", { filter: { ...request } })
      .then(({ data }) => {
        setRooms(data);
      })
      .catch((error) => {
        notify(error);
      });
    dataProvider
      .getList("bookings", { filter: { ...request } })
      .then(({ data }) => {
        setAvailableBooking(data);
      })
      .catch((error) => {
        notify(error);
      });
    dataProvider
      .getList("accommodations", { filter: { ...request } })
      .then(({ data }) => {
        setAccommodations(data);
      })
      .catch((error) => {
        notify(error);
      });
    dataProvider
      .getList("maintenances", { filter: { ...request } })
      .then(({ data }) => {
        setMaintenances(data);
      })
      .catch((error) => {
        notify(error);
      });
  }, []);

  const addAccommodation = (formData) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    let booking = availableBooking.filter((item) => {
      return item.id == formData.booking_id;
    })[0];

    var isValidRoom = true;
    var isValidSlot = true;

    if (formData.room_id != null) {
      // check room
      let selectedRoom = rooms.filter((item) => item.id == formData.room_id)[0];
      if (selectedRoom.status != "Active") {
        isValidRoom = false;
      }

      //check availability
      let from_date = new Date(formData.check_in_date).setHours(0, 0, 0, 0);
      let to_date = new Date(formData.check_out_date).setHours(0, 0, 0, 0);
      for (var dateArr = [], dt = new Date(from_date); dt < to_date; dt.setDate(dt.getDate() + 1)) 
      {
        dateArr.push(new Date(dt));
      }
      dateArr.map((date) => {
        var accommodationBlock = accommodations.filter(
          (acco) =>
            acco.room_id == formData.room_id &&
            new Date(acco.check_in_date).setHours(0, 0, 0, 0) <=
              date.setHours(0, 0, 0, 0) &&
            new Date(acco.check_out_date).setHours(0, 0, 0, 0) >
              date.setHours(0, 0, 0, 0)
        );
        var maintenanceBlock = maintenances.filter(
          (maint) =>
            maint.room_id == formData.room_id &&
            new Date(maint.start_date).setHours(0, 0, 0, 0) <=
              date.setHours(0, 0, 0, 0) &&
            new Date(maint.end_date).setHours(0, 0, 0, 0) >
              date.setHours(0, 0, 0, 0)
        );

        if (accommodationBlock.length > 0 || maintenanceBlock.length > 0) {
          isValidSlot = false;
        }
      });
    }

    if (!isValidRoom) {
      notify("Selected room is out of order");
    } else if (!isValidSlot) {
      notify("Selected date is not available");
    } else {
      var request = {
        tenant_id: authInfo.tenant.id,
        check_in_date: formData.check_in_date,
        check_out_date: formData.check_out_date,
        room_type_id: formData.room_type_id,
        room_type_rate_id: formData.room_type_rate_id,
        room_id: formData.room_id,
        booking_id: booking.id,
        status: formData.room_id != null ? "Confirmed" : "New",
      };
      dataProvider.create("accommodations", { data: request })
        .then(({ data }) => {

          //add order
          let room_type = availableRoomType.filter((item) => {return(item.id == formData.room_type_id)})[0];
          let room_type_rate = room_type.room_type_rates.filter((item) => {return(item.id == formData.room_type_rate_id)})[0];
          let order_request = {
            tenant_id: authInfo.tenant.id,
            accommodation_id: data.id,
            service_id: null,
            qty: datediff(parseDate(formData.check_in_date),parseDate(formData.check_out_date)),
            price: room_type_rate.price,
            service_charge: room_type_rate.service_charge,
            total_price:(room_type_rate.price + room_type_rate.price * room_type_rate.service_charge) * datediff(parseDate(formData.check_in_date),parseDate(formData.check_out_date)),
            booking_id: booking.id,
            description:room_type.name + " (" + formData.check_in_date + " - " + formData.check_out_date + ")",
            order_type: "Accommodation",
            status: "New",
          };
          dataProvider.create("orders", { data: order_request });

          //update booking if got changes
          if (formData.room_id != null && booking.status == "New") 
          { 
            dataProvider.update("bookings", {id: booking.id,data: { status: "Confirmed" },});
          }

          // refreshBooking();
          notify("Accommodation created");
        })
        .catch((error) => {
          notify(error);
        });
    }
  };

  const updateAccommodation = (process, formData) => {
    const authInfo = JSON.parse(localStorage.getItem("auth"));
    let booking = availableBooking.filter((item) => {
      return item.id == formData.booking_id;
    })[0];

    var isValidRoom = true;
    var isValidSlot = true;

    if (formData.room_id != null) {
      //check room
      let selectedRoom = rooms.filter((item) => item.id == formData.room_id)[0];
      if (selectedRoom.status != "Active") {
        isValidRoom = false;
      }

      //check availability
      let from_date = new Date(formData.check_in_date).setHours(0, 0, 0, 0);
      let to_date = new Date(formData.check_out_date).setHours(0, 0, 0, 0);
      for (var dateArr = [], dt = new Date(from_date);dt < to_date;dt.setDate(dt.getDate() + 1)) 
      {
        dateArr.push(new Date(dt));
      }
      dateArr.map((date) => {
        var accommodationBlock = accommodations
          .filter((acco) => acco.id != props.record?.id)
          .filter(
            (acco) =>
              acco.room_id == formData.room_id &&
              new Date(acco.check_in_date).setHours(0, 0, 0, 0) <=
                date.setHours(0, 0, 0, 0) &&
              new Date(acco.check_out_date).setHours(0, 0, 0, 0) >
                date.setHours(0, 0, 0, 0)
          );
        var maintenanceBlock = maintenances.filter(
          (maint) =>
            maint.room_id == formData.room_id &&
            new Date(maint.start_date).setHours(0, 0, 0, 0) <=
              date.setHours(0, 0, 0, 0) &&
            new Date(maint.end_date).setHours(0, 0, 0, 0) >
              date.setHours(0, 0, 0, 0)
        );

        if (accommodationBlock.length > 0 || maintenanceBlock.length > 0) {
          isValidSlot = false;
        }
      });
    }

    //check valid
    var isValid = true;
    var request = {};
    if (process == "update") {
      let existingOrder = booking.orders.filter((item) => {
        return (item.accommodation_id == props.record?.id &&item.order_type == "Accommodation" && (item.status == "Invoiced" || item.status == "Paid"));
      });
      //if order is paid or invoiced, not allow update
      if (existingOrder.length > 0) {
        notify("Fail to update due to order already invoiced or paid");
        isValid = false;
      }

      request.check_in_date = formData.check_in_date;
      request.check_out_date = formData.check_out_date;
      request.room_type_id = formData.room_type_id;
      request.room_type_rate_id = formData.room_type_rate_id;
      request.room_id = formData.room_id;
      request.status = props.record?.status == "New" && formData.room_id != null? "Confirmed" : props.record?.status;
    } else if (process == "checkin") {
      //if no room, not allow checkin
      if (!formData.room_id) {
        notify("Please select a room to check in");
        isValid = false;
      }
      request.check_in_date = formData.check_in_date;
      request.check_out_date = formData.check_out_date;
      request.room_type_id = formData.room_type_id;
      request.room_type_rate_id = formData.room_type_rate_id;
      request.room_id = formData.room_id;
      request.status = "Checked_In";
    } else if (process == "checkout") {
      let existingOrder = booking.orders.filter((item) => {
        return (item.accommodation_id == props.record?.id && item.order_type == "Accommodation" &&(item.status == "Invoiced" || item.status == "Paid"));
      });
      // //if order is paid or invoiced, not allow update
      // if (existingOrder.length > 0 && props.record?.check_out_date != formData.check_out_date
      // ) {
      //   notify("Fail to update due to order already invoiced or paid");
      //   isValid = false;
      // }

      //if not all order is paid, not allow check out
      if (booking.orders.filter((record) =>record.accommodation_id == props.record?.id &&(record.status == "New" || record.status == "Invoiced")).length > 0) 
      {
        notify("Please invoice & paid all related order before check out.");
        isValid = false;
      }

      request.check_out_date = formData.check_out_date;
      request.status = "Checked_Out";
    } else if (process == "cancel") {
      let existingOrder = booking.orders.filter((item) => {
        return (item.accommodation_id == props.record?.id &&item.order_type == "Accommodation" &&(item.status == "Invoiced" || item.status == "Paid"));
      });
      //if order is invoiced or paid, not allow to cancel
      if (existingOrder.length > 0) {
        notify("Fail to update due to order already invoiced or paid");
        isValid = false;
      }
      request.status = "Cancelled";
    }


    if (!isValidRoom) {
      notify("Selected room is out of order");
    } else if (!isValidSlot) {
      notify("Selected date is not available");
    } else if (isValid) {
      dataProvider.update("accommodations", {id: props.record?.id,data: { ...request },})
        .then(({ data }) => {
          //update room dirty
          if (data.status == "Checked_Out") {
            dataProvider.update("rooms", {id: data.room_id,data: { clean_status: "Dirty" },});
          }

          //update order if got changes
          let order = booking.orders.filter((item) => {return (item.accommodation_id == props.record?.id && item.order_type == "Accommodation" && item.status == "New")});
          if(order.length > 0 &&
            (props.record?.check_in_date != formData.check_in_date || 
              props.record?.check_out_date != formData.check_out_date ||
              props.record?.room_type_id != formData.room_type_id ||
              props.record?.room_type_rate_id != formData.room_type_rate_id))
          {
            let room_type = availableRoomType.filter((item) => {return(item.id == formData.room_type_id)})[0];
            let room_type_rate = room_type.room_type_rates.filter((item) => {return(item.id == formData.room_type_rate_id)})[0];

            let order_request = {
              qty: datediff(parseDate(formData.check_in_date),parseDate(formData.check_out_date)),
              price: room_type_rate.price,
              service_charge: room_type_rate.service_charge,
              total_price: (room_type_rate.price + room_type_rate.price * room_type_rate.service_charge) * datediff(parseDate(formData.check_in_date),parseDate(formData.check_out_date)),
              description: room_type.name + " (" + formData.check_in_date + " - " + formData.check_out_date + ")",
            }
            dataProvider.update("orders", {id: order.id,data: { ...order_request }});
          }

          //update booking status
          let bookingStatus = "New";
          if ((data.status == "Confirmed" && data.room_id != null && booking.status == "New") ||
            booking.accommodations.filter((record) =>record.id != props.record?.id && record.room_id != null).length > 0) 
          {
            //from new to confirm
            bookingStatus = "Confirmed";
          }
          if (data.status == "Checked_In" && data.room_id != null && booking.status == "Confirmed") 
          {
            //from confirm to checkin
            bookingStatus = "Checked_In";
          }
          if (data.status == "Cancalled" && 
          booking.accommodations.filter((record) => record.id != props.record?.id && record.status == "Cancelled").length ==data.accommodations.length - 1) 
          {
            //if all accommodation is cancel, to cancel
            bookingStatus = "Cancelled";
          }
          if ((data.status == "Cancalled" || data.status == "Checked_Out") &&
            booking.accommodations.filter((record) => record.id != props.record?.id && (record.status == "New" || record.status == "Checked_In" ||record.status == "Confirmed" ||record.status == "Checked_In")).length == 0) 
          {
            //if all is end, to complete
            bookingStatus = "Completed";
          }

          if (booking.status != bookingStatus) {
            dataProvider.update("bookings", {id: booking.id,data: { status: bookingStatus }});
          }

          // refreshBooking();
          notify("Accommodation updated");
        })
        .catch((error) => {
          notify(error)
        });
    }
  };

  const refreshBooking = () => {
    window.location.href = "/accommodations";
  };

  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    Accommodation Info
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <SelectInput
                    className={classes.formInput}
                    fullWidth
                    label="Booking No"
                    source="booking_id"
                    choices={availableBooking?.map(function (item) {
                      return {
                        id: item.id,
                        name: formatter.bookingNo(item.booking_no),
                      };
                    })}
                    options={{
                      InputProps: { autoComplete: "no" },
                    }}
                    validate={[required()]}
                    disabled={
                      props.record?.status == "Checked_In" ||
                      props.record?.status == "Checked_Out" ||
                      props.record?.status == "Cancelled"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    source="check_in_date"
                    label="Check In Date"
                    defaultValue={today}
                    validate={[required()]}
                    disabled={
                      props.record?.status == "Checked_In" ||
                      props.record?.status == "Checked_Out" ||
                      props.record?.status == "Cancelled"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <DateInput
                    className={classes.formInput}
                    fullWidth
                    source="check_out_date"
                    label="Check Out Date"
                    defaultValue={tomorrow}
                    validate={[required()]}
                    disabled={
                      props.record?.status == "Checked_Out" ||
                      props.record?.status == "Cancelled"
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <SelectInput
                    className={classes.formInput}
                    fullWidth
                    label="Room Type"
                    source="room_type_id"
                    choices={availableRoomType?.map(function (item) {
                      return { id: item.id, name: item.name };
                    })}
                    options={{
                      InputProps: { autoComplete: "no" },
                    }}
                    validate={[required()]}
                    disabled={
                      props.record?.status == "Checked_In" ||
                      props.record?.status == "Checked_Out" ||
                      props.record?.status == "Cancelled"
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.room_type_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Room Type Rate"
                          source="room_type_rate_id"
                          choices={availableRoomType
                            ?.filter(
                              (fitem) => fitem.id === formData.room_type_id
                            )[0]
                            ?.room_type_rates.map((item) => {
                              return {
                                id: item.id,
                                name: item.room_rate.name,
                              };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          validate={[required()]}
                          disabled={
                            props.record?.status == "Checked_In" ||
                            props.record?.status == "Checked_Out" ||
                            props.record?.status == "Cancelled"
                          }
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={12} className={classes.formGrid}>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      formData.room_type_id && formData.room_type_rate_id ? (
                        <SelectInput
                          className={classes.formInput}
                          fullWidth
                          label="Room"
                          source="room_id"
                          choices={availableRoomType
                            ?.filter(
                              (fitem) => fitem.id === formData.room_type_id
                            )[0]
                            ?.rooms.map((item) => {
                              return { id: item.id, name: item.name };
                            })}
                          optionText="name"
                          options={{
                            InputProps: { autoComplete: "no" },
                          }}
                          disabled={
                            props.record?.status == "Checked_In" ||
                            props.record?.status == "Checked_Out" ||
                            props.record?.status == "Cancelled"
                          }
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </Grid>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Cancellation Policy</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_room_type = availableRoomType?.filter(
                      (fitem) => fitem.id === formData.room_type_id
                    )[0];

                    var selected_room_type_rate = selected_room_type
                      ? selected_room_type.room_type_rates.filter(
                          (fitem) => fitem.id === formData.room_type_rate_id
                        )[0]
                      : null;

                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_room_type_rate
                          ? selected_room_type_rate.room_rate
                              .cancellation_policy.name
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Price</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_room_type = availableRoomType?.filter(
                      (fitem) => fitem.id === formData.room_type_id
                    )[0];

                    var selected_room_type_rate = selected_room_type
                      ? selected_room_type.room_type_rates.filter(
                          (fitem) => fitem.id === formData.room_type_rate_id
                        )[0]
                      : null;
                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_room_type_rate
                          ? formatter.formatMoney(
                              selected_room_type_rate.price,
                              authInfo.tenant.locale.currency_code,
                              authInfo.tenant.locale.precision
                            )
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                <Grid item xs={6} className={classes.formGrid}>
                  <Typography gutterBottom>Tax</Typography>
                </Grid>
                <FormDataConsumer>
                  {({ formData, ...rest }) => {
                    var selected_room_type = availableRoomType?.filter(
                      (fitem) => fitem.id === formData.room_type_id
                    )[0];

                    var selected_room_type_rate = selected_room_type
                      ? selected_room_type.room_type_rates.filter(
                          (fitem) => fitem.id === formData.room_type_rate_id
                        )[0]
                      : null;
                    return (
                      <Grid
                        item
                        xs={6}
                        className={classes.formGrid}
                        align="right"
                      >
                        {selected_room_type_rate
                          ? formatter.formatMoney(
                              selected_room_type_rate.service_charge *
                                selected_room_type_rate.price,
                              authInfo.tenant.locale.currency_code,
                              authInfo.tenant.locale.precision
                            )
                          : "-"}
                      </Grid>
                    );
                  }}
                </FormDataConsumer>
                {Object.keys(props.record).length != 0 ? (
                  <Grid item xs={6} className={classes.formGrid}>
                    <Typography gutterBottom>Status</Typography>
                  </Grid>
                ) : null}
                {Object.keys(props.record).length != 0 ? (
                  <Grid
                    item
                    xs={6}
                    className={classes.formGrid}
                    style={{ textAlign: "right" }}
                  >
                    <Chip
                      label={props.record?.e_accommodation_status.comment}
                      style={{
                        color: "white",
                        background: accommodationStatus.getColor(
                          props.record?.status
                        ),
                      }}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </CardContent>

            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <div>
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      Object.keys(props.record).length == 0 ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          className={classes.formInput}
                          onSave={() => addAccommodation(formData)}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                  &nbsp;
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      props.record?.status == "New" ||
                      props.record?.status == "Confirmed" ||
                      props.record?.status == "Checked_In" ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          label="Update"
                          icon={<SaveIcon />}
                          className={classes.formInput}
                          onSave={() => updateAccommodation("update", formData)}
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                  &nbsp;
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      props.record?.status == "New" ||
                      props.record?.status == "Confirmed" ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          label="Check In"
                          icon={<ArrowDownwardIcon />}
                          className={classes.formInput}
                          onSave={() =>
                            updateAccommodation("checkin", formData)
                          }
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                  &nbsp;
                  <FormDataConsumer>
                    {({ formData, ...rest }) =>
                      props.record?.status == "Checked_In" ? (
                        <SaveButton
                          {...formProps}
                          variant="contained"
                          color="primary"
                          label="Check Out"
                          icon={<ArrowUpwardIcon />}
                          className={classes.formInput}
                          onSave={() =>
                            updateAccommodation("checkout", formData)
                          }
                        />
                      ) : null
                    }
                  </FormDataConsumer>
                </div>

                <FormDataConsumer>
                  {({ formData, ...rest }) =>
                    props.record?.status == "New" ||
                    props.record?.status == "Confirmed" ? (
                      <SaveButton
                        {...formProps}
                        style={{ background: "red" }}
                        variant="contained"
                        color="primary"
                        label="Cancel"
                        icon={<ClearIcon />}
                        className={classes.formInput}
                        onSave={() => updateAccommodation("cancel", formData)}
                      />
                    ) : null
                  }
                </FormDataConsumer>
              </Box>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
};

export default AccommodationForm;
