export const roomStatus = {
  getColor,
};

function getColor(status) {
  switch (status) {
    case "Active":
      return "darkgreen";
    case "Out_Of_Order":
      return "darkred";
    default:
      return "white";
  }
}
