import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  SearchInput,
  FunctionField,
  useListContext,
  TopToolbar,
  ExportButton,
  ReferenceInput,
  SelectInput,
  TextInput,
  sanitizeListRestProps,
  downloadCSV,
} from "react-admin";
import { depositStatus } from "../../models/depositStatus";
import { formatter } from "../../utils/formatter";
import Chip from "@material-ui/core/Chip";
import moment from "moment";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import { InputAdornment } from "@material-ui/core";
import jsonExport from "jsonexport/dist";


const filters = [
  <TextInput
    type="number"
    source="deposit_no"
    label="Deposit No"
    alwaysOn
    resettable
    InputProps={{
      startAdornment: <InputAdornment>D</InputAdornment>,
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />,
  <ReferenceInput
    style={{ width: 250 }}
    label="Type"
    source="type"
    reference="e_deposit_types"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
  <ReferenceInput
    style={{ width: 250 }}
    label="Status"
    source="status"
    reference="e_deposit_status"
    alwaysOn
    resettable
  >
    <SelectInput optionText="comment" />
  </ReferenceInput>,
];

const ListActions = (props) => {
  const { className, filters, maxResults, ...rest } = props;
  const { total } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      <ExportButton disabled={total === 0} maxResults={maxResults} />
    </TopToolbar>
  );
};

const exporter = (records) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const forExport = records.map((record) => {
    return {
      deposit_no: formatter.depositNo(record.deposit_no),
      type: record.e_deposit_type.comment,
      amount: formatter.formatMoney(
        record.amount,
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      used_amount: formatter.formatMoney(
        record.invoice_payments.reduce((total, obj) => obj.amount + total, 0),
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      refunded_amount: formatter.formatMoney(
        record.refunds
          .filter((item) => {
            return item.status != "Cancelled";
          })
          .reduce((total, obj) => obj.amount + total, 0),
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      remaining_amount: formatter.formatMoney(
        record.amount -
          record.invoice_payments.reduce(
            (total, obj) => obj.amount + total,
            0
          ) -
          record.refunds
            .filter((item) => {
              return item.status != "Cancelled";
            })
            .reduce((total, obj) => obj.amount + total, 0),
        authInfo.tenant.locale.currency_code,
        authInfo.tenant.locale.precision
      ),
      created_date: moment(record.created_at).format("DD MMM YYYY hh:mm:ss A"),
      status: record.e_deposit_status.comment,
    };
  });
  jsonExport(
    forExport,
    {
      headers: [
        "deposit_no",
        "type",
        "amount",
        "used_amount",
        "refunded_amount",
        "remaining_amount",
        "created_date",
        "status",
      ],
    },
    (err, csv) => {
      downloadCSV(csv, "deposits");
    }
  );
};

const DepositList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  return (
    <List
      filters={filters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      actions={<ListActions />}
      sort={{ field: "created_at", order: "DESC" }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <FunctionField
          sortBy="deposit_no"
          label="Deposit No"
          render={(record) => formatter.depositNo(record.deposit_no)}
        />
        <TextField source="e_deposit_type.comment" label="Type" />
        <FunctionField
          sortBy="amount"
          label="Amount"
          render={(record) =>
            formatter.formatMoney(
              record.amount,
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          label="Used Amount"
          render={(record) =>
            formatter.formatMoney(
              record.invoice_payments.reduce(
                (total, obj) => obj.amount + total,
                0
              ),
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          label="Refunded Amount"
          render={(record) =>
            formatter.formatMoney(
              record.refunds
                .filter((item) => {
                  return item.status != "Cancelled";
                })
                .reduce((total, obj) => obj.amount + total, 0),
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          label="Remaining Amount"
          render={(record) =>
            formatter.formatMoney(
              record.amount -
                record.invoice_payments.reduce(
                  (total, obj) => obj.amount + total,
                  0
                ) -
                record.refunds
                  .filter((item) => {
                    return item.status != "Cancelled";
                  })
                  .reduce((total, obj) => obj.amount + total, 0),
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          sortBy="created_at"
          label="Created Date"
          render={(record) =>
            moment(record.created_at).format("DD MMM YYYY hh:mm:ss A")
          }
        />
        <FunctionField
          sortBy="status"
          label="Status"
          render={(record) => (
            <Chip
              label={record.e_deposit_status.comment}
              style={{
                color: "white",
                background: depositStatus.getColor(record.e_deposit_status.id),
              }}
            />
          )}
        />
        <FunctionField
          render={(record) => (
            <Button
              style={{ border: "none", float: "right" }}
              startIcon={<EditIcon />}
              variant="outlined"
              color="primary"
              href={`/bookings/${record.booking_id}`}
              target="_blank"
            >
              View Booking
            </Button>
          )}
        />
      </Datagrid>
    </List>
  );
}

export default DepositList;
