import React, { useEffect } from "react";
import { Title, useTranslate } from "react-admin";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, TextField } from "@material-ui/core";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";
import { formatter } from "../../utils/formatter";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { reportApi } from "../../api/reportApi";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const SalesReport = () => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [salesReport, setSalesReport] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const authInfo = JSON.parse(localStorage.getItem("auth"));
  const currency = authInfo.tenant.locale.currency_code;
  useEffect(() => {
    searchReport();
  }, []);

  const searchReport = () => {
    reportApi
      .getSalesReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => setSalesReport(data.get_sales_report))
      .catch((error) => notify(error));
  };

  return (
    <div>
      <Title title={translate("ra.label.sales")} />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 12,
        }}
      >
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            style={{
              marginTop: 12,
            }}
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkInDate}
            onChange={(e) => setCheckInDate(e.target.value)}
            fullWidth
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkOutDate}
            onChange={(e) => setCheckOutDate(e.target.value)}
            fullWidth
            label="End Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <Button
            style={{
              marginTop: 12,
              marginRight: 5,
            }}
            onClick={searchReport}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          {reportList(salesReport)}
        </Grid>
      </Grid>
    </div>
  );

  function reportList(salesData) {
    return (
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Created date</TableCell>
              <TableCell>Booking no</TableCell>
              <TableCell>Order no</TableCell>
              <TableCell>Item</TableCell>
              <TableCell>Qty</TableCell>
              <TableCell>Service charge</TableCell>
              <TableCell>Total amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salesData?.map((row) => {
              return (
                <TableRow>
                  <TableCell>{row.created_at.split("T")[0]}</TableCell>
                  <TableCell>{formatter.bookingNo(row.booking_no)}</TableCell>
                  <TableCell>{formatter.orderNo(row.order_no)}</TableCell>
                  <TableCell>{row.item_name}</TableCell>
                  <TableCell>{row.qty}</TableCell>
                  <TableCell>
                    {formatter.formatMoney(
                      row.service_charge,
                      authInfo.tenant.locale.currency_code,
                      authInfo.tenant.locale.precision
                    )}
                  </TableCell>
                  <TableCell>
                    {formatter.formatMoney(
                      row.total_amount,
                      authInfo.tenant.locale.currency_code,
                      authInfo.tenant.locale.precision
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
};

export default SalesReport;
/* 
const reportPage = (translatableTitle, rowValuesExtractor, columnTitles) => {
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [reportData, setReportData] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const authInfo = JSON.parse(localStorage.getItem("auth"));

  useEffect(reloadReportPageData, []);

  const reloadReportPageData = () => {
    reportApi
      .getSalesReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => setSalesReport(data.get_sales_report))
      .catch((error) => notify(error));
  };

  return _reportList(reportData, rowValuesExtractor, columnTitles);
};

function _reportList(reportData, rowValuesExtractor, columnTitles) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {rowTitle?.map((rowItemTitle) => (
              <TableCell>{rowItemTitle}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {reportData.map((row) => (
            <TableRow>
              {rowValuesExtractor(row).map((rowItemValue) => (
                <TableCell>{rowItemValue}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
 */
