import gql from 'graphql-tag';

const GET_LIST_REFUNDS = gql`
{
  amount
  booking_id
  created_at
  deposit_id
  id
  order_id
  refund_no
  status
  tenant_id
  type
  updated_at
  deposit {
    deposit_no
  }
  e_refund_status {
    comment
    id
  }
  e_refund_type {
    comment
    id
  }
  order {
    order_no
  }
  beneficiary_acc_no
  beneficiary_bank_name
  beneficiary_name
  completed_at
  remarks
}
`;

const GET_ONE_REFUNDS = gql`
{
  amount
  booking_id
  created_at
  deposit_id
  id
  order_id
  refund_no
  status
  tenant_id
  type
  updated_at
  deposit {
    deposit_no
  }
  e_refund_status {
    comment
    id
  }
  e_refund_type {
    comment
    id
  }
  order {
    order_no
  }
  beneficiary_acc_no
  beneficiary_bank_name
  beneficiary_name
  completed_at
  remarks
}
`;

export{GET_LIST_REFUNDS, GET_ONE_REFUNDS}
