import React, { useContext, useEffect } from "react";
import {
  FormWithRedirect,
  List,
  Datagrid,
  EditButton,
  SearchInput,
  FunctionField,
  useTranslate,
  Title,
} from "react-admin";
import { roomCleanStatus } from "../../models/roomCleanStatus";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import { useNotify, useRedirect } from "react-admin";
import { DataProviderContext } from "react-admin";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  Typography,
  Box,
  InputAdornment,
  Button,
  TextField,
} from "@material-ui/core";
import moment from "moment";
import { getFirstLastDayInCurrentMonth } from "../../utils/date";
import { formatter } from "../../utils/formatter";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import { reportApi } from "../../api/reportApi";

const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  subCardWrap: {
    minHeight: 260,
  },
}));

const OccupancyReport = () => {
  const notify = useNotify();
  const classes = useStyles();

  const [initialCheckInDate, initialCheckOutDate] =
    getFirstLastDayInCurrentMonth();

  const [occupencyData, setOccupencyData] = React.useState([]);
  const [checkInDate, setCheckInDate] = React.useState(initialCheckInDate);
  const [checkOutDate, setCheckOutDate] = React.useState(initialCheckOutDate);

  const authInfo = JSON.parse(localStorage.getItem("auth"));

  useEffect(() => {
    searchReport();
  }, []);

  const searchReport = () => {
    reportApi
      .getOccupancyReport(authInfo.tenant.id, checkOutDate, checkInDate)
      .then(({ data }) => setOccupencyData(data.get_occupancy_report))
      .catch((error) => notify(error));
  };

  return (
    <div>
      <Title title={"Occupancy"} />
      <Grid
        container
        spacing={2}
        style={{
          marginTop: 12,
        }}
      >
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            style={{
              marginTop: 12,
            }}
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkInDate}
            onChange={(e) => setCheckInDate(e.target.value)}
            fullWidth
            label="Start Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <TextField
            type="date"
            variant="filled"
            className={classes.formInput}
            value={checkOutDate}
            onChange={(e) => {
              setCheckOutDate(e.target.value);
            }}
            fullWidth
            label="End Date"
          />
        </Grid>
        <Grid item xs={12} sm={3} className={classes.formGrid}>
          <Button
            style={{
              marginTop: 12,
              marginRight: 5,
            }}
            onClick={searchReport}
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </Grid>
        <Grid item xs={12}>
          {reportList(occupencyData)}
        </Grid>
      </Grid>
    </div>
  );
};

function reportList(occupencyData) {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Room type</TableCell>
            <TableCell>Room qty</TableCell>
            <TableCell>Occupied rooms qty</TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {occupencyData?.map((row) => (
            <TableRow>
              <TableCell>{row.a_date}</TableCell>
              <TableCell>{row.room_type}</TableCell>
              <TableCell>{row.room_qty}</TableCell>
              <TableCell>{row.occupied_rooms_qty ?? 0}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default OccupancyReport;
