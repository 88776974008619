import React, { useContext } from 'react';
import {
  FormWithRedirect,
  TextInput,
  NumberInput,
  SelectInput,
  ImageInput,
  ImageField,
  DeleteButton,
  required,
  minLength,
  maxLength,
  useTranslate,
  ArrayInput,
  SimpleFormIterator,
  CheckboxGroupInput,
  ReferenceInput,
  AutocompleteInput,
  Toolbar,
  ReferenceArrayInput,
  SaveButton
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { Typography, Box, Select, FormGroup, Checkbox, FormControlLabel, InputAdornment } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent,Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { useFormState } from 'react-final-form';
import { useNotify, useRedirect } from 'react-admin';
import { DataProviderContext } from 'react-admin';
import CustomSaveButton from './CustomSaveButton';
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { mealsApi } from '../../api/mealsApi';


const useStyles = makeStyles((theme) => ({
  formGrid: {
    paddingTop: "0!important",
    paddingBottom: "0!important",
  },
  formInput: {
    marginTop: "0!important",
    marginBottom: "0!important",
  },
  textBold: {
    fontWeight: "bold",
  },
  // groupLabel: {
  //   color: "rgba(0, 0, 0, 0.54)",
  //   fontSize: "14px"
  // }
}));

const MealsForm = props => {
  const authInfo = JSON.parse(localStorage.getItem('auth'));
  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();
  const redirect = useRedirect();
  const [openDelete, setOpenDelete] = React.useState(false);
  const { source, ...rest } = props;
    
  const handleOpenDelete = () => {
    setOpenDelete(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleConfirmDelete = (id) => { 
    mealsApi.deleteMeal(id)
      .then((ret) => {
        redirect(`/meals`);
        notify("Meal deleted");
      },
        (error) => {
          notify(error);
        });
  };
  return (
    <FormWithRedirect
      {...props}
      render={(formProps) => (
        <Card>
          <form>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography
                    variant="h6"
                    className={classes.textBold}
                    gutterBottom
                  >
                    {'Meals'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <TextInput
                    className={classes.formInput}
                    fullWidth
                    resource="meals"
                    source="name"
                    label="Name"
                    validate={[required()]}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                    <NumberInput fullWidth required className={classes.formInput} resource="services" source="price" label="Price" InputProps={{
                        startAdornment: <InputAdornment position="start">{authInfo.tenant.locale.currency_code}</InputAdornment>
                    }} />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}>
                  <NumberInput fullWidth validate={[required()]} className={classes.formInput} resource="meals" source="service_charge" label="Service Charge"  InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}/>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.formGrid}></Grid>
              </Grid>
            </CardContent>
            <Toolbar>
              <Box display="flex" justifyContent="space-between" width="100%">
                <CustomSaveButton {...formProps} />
                {Object.keys(props.record).length === 0 ?
                  null :
                  <Button
                    style={{ color: "red" }}
                    variant="outline"
                    color="primary"
                    startIcon={<DeleteIcon />}
                    onClick={handleOpenDelete}
                  >
                    Delete
                  </Button>}
              </Box>
              <Dialog
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Confirm Delete?"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please confirm to delete
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleCloseDelete()} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={() => handleConfirmDelete(formProps.record.id)}
                    color="primary"
                    autoFocus
                  >
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Toolbar>
          </form>
        </Card>
      )}
    />
  );
}

export default MealsForm;