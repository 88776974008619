import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  EditButton,
  Filter,
  SearchInput,
  TextInput,
  BooleanInput,
  FunctionField,
  ReferenceInput,
  SelectInput,
  downloadCSV,
  NumberField,
  NumberInput,
} from "react-admin";
import { Button, InputAdornment } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { guestStatus } from "../../models/guestStatus";
import Chip from "@material-ui/core/Chip";
import jsonExport from "jsonexport/dist";
import { booleanStatus } from "../../models/booleanStatus";
import { formatter } from "../../utils/formatter";

const RoomServicesFilters = [
  <TextInput
    source="name"
    label="Name"
    alwaysOn
    resettable
    InputProps={{
      endAdornment: (
        <InputAdornment>
          <SearchIcon color="disabled" />
        </InputAdornment>
      ),
    }}
  />
];

const exporter = (records) => {
  const forExport = records.map((record) => {
    return {
      name: record.name,
      price: record.price,
      is_active: record.is_active,
      created_at: record.created_at,
    };
  });
  jsonExport(
    forExport,
    {
      headers: ["name", "price", "isActive", "CreatedAt"],
    },
    (err, csv) => {
      downloadCSV(csv, "cm_acounts");
    }
  );
};

const RoomServicesList = (props) => {
  const authInfo = JSON.parse(localStorage.getItem("auth"));

  return (
    <List
      sort={{ field: "is_active", order: "Desc" }}
      filters={RoomServicesFilters}
      filterDefaultValues={{ tenant_id: authInfo.tenant.id }}
      bulkActionButtons={false}
      exporter={exporter}
      {...props}
    >
      <Datagrid>
        <TextField source="name" label="Name" />
        <FunctionField
          label="Price"
          render={(record) =>
            formatter.formatMoney(
              record.price,
              authInfo.tenant.locale.currency_code,
              authInfo.tenant.locale.precision
            )
          }
        />
        <FunctionField
          label="Service Charge"
          render={(record) =>
            (record.service_charge * 100) + "%"
          }
        />
        <FunctionField
          sortBy="is_active"
          label="Status"
          render={(record) => (
            <Chip
              label={record.is_active ? "Active" : "Inactive"}
              style={{
                color: "white",
                background: booleanStatus.getColor(record.is_active),
              }}
            />
          )}
        />
        <EditButton style={{ float: "right" }} basePath="/services" />
      </Datagrid>
    </List>
  );
};

export default RoomServicesList;
